import {Injectable} from '@angular/core';
import packageJson from '../../../../../package.json';
import {ConfirmationDialogComponent} from '../../components/confirmation-dialog/confirmation-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {Location} from '@angular/common';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VersioningService {
  private server_version: string;
  public current_version: string = packageJson.version;
  public triggered = false;

  constructor(
    private location: Location,
    public dialog: MatDialog) {
  }

  private static compareVersions(versionA: string, versionB: string): number {
    const segmentsA = versionA.split('.').map(Number);
    const segmentsB = versionB.split('.').map(Number);
    for (let i = 0; i < Math.max(segmentsA.length, segmentsB.length); i++) {
      const segmentA = segmentsA[i] || 0;
      const segmentB = segmentsB[i] || 0;
      if (segmentA < segmentB) {
        return -1; // versionA is smaller
      } else if (segmentA > segmentB) {
        return 1; // versionA is larger
      }
    }
    return 0; // Both versions are equal
  }

  check(version) {
    if (environment.selfUrl !== 'https://planafy.com/') {
      return;
    }
    this.server_version = version;
    if (!this.server_version) {
      console.log('No server version');
      return;
    }
    if (!this.current_version) {
      console.log('No web version');
      return;
    }
    if (VersioningService.compareVersions(this.current_version, this.server_version) === -1 && !this.triggered) {
      this.triggered = true;
      this.update();
    } else {
      this.triggered = false;
    }
  }

  update() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      autoFocus: true,
      data: {disabled: false, confirmTitle: 'Update', confirmMessage: 'New version, please refresh page'}
    });
    dialogRef.afterClosed().subscribe(reason => {
      if (reason) {
        this.location.replaceState(this.location.path());
        window.location.reload();
      } else {
        this.triggered = false;
      }
    });
  }
}
