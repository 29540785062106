import {Component, EventEmitter, Inject, OnDestroy, OnInit} from '@angular/core';
import {TourPax} from '../../../../../shared/models/tour-pax.model';
import {SnackbarService} from '../../../../../shared/services/common/snackbar.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Form} from '../../../../../shared/models/form.model';
import {DatePipe} from '@angular/common';
import {takeUntil} from 'rxjs/operators';
import {HttpFormService} from '../../../../../shared/services/http/http-form.service';
import {Subject} from 'rxjs';
import {Question} from '../../../../../shared/models/question.model';

export interface DialogDataNewAnswer {
  form: Form;
  tour_pax: TourPax[];
  responses: string;
  tp_id: number;
  user_id: number;
  reason: string;
}

@Component({
  selector: 'app-new-answer',
  templateUrl: './new-answer.component.html',
  styleUrls: ['./new-answer.component.scss']
})
export class NewAnswerComponent implements OnInit, OnDestroy {

  listChecked: any[] = [];
  idxDone: number[] = [];
  loading = false;
  dateTouch = false;
  timeTouch = false;
  errMessage: string;
  ext = false;
  date = null;
  time = null;

  onUpdate = new EventEmitter();
  private onDestroy$ = new Subject<boolean>();

  constructor(
    private snackSvc: SnackbarService,
    private datePipe: DatePipe,
    private httpForm: HttpFormService,
    public dialogRef: MatDialogRef<NewAnswerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataNewAnswer) {
    this.data.tour_pax = this.data.tour_pax.filter(tp => tp.active && !tp.hidden).sort((a, b) =>
      a.user['name'].toLowerCase() > b.user['name'].toLowerCase() ? 1 :
        a.user['name'].toLowerCase() === b.user['name'].toLowerCase() ? 0 : -1);
    this.data.form.questions = this.data.form.questions.filter(q => q.question_type.type !== 10);
  }

  ngOnInit(): void {
    this.resetForm();
  }

  onSelectTourpax(e) {
    // console.log(e);
  }

  resetForm() {
    this.idxDone = [];
    this.date = null;
    this.time = null;
    this.dateTouch = false;
    this.timeTouch = false;
    this.data.form.questions.map(q => {
      q.listCheckedIds = [];
      q.other = null;
      q.multiquestions.map(mq => {
        mq.checked = false;
        if (mq.option === 'Other...') {
          mq.option = null;
        }
      });
      q.answers.map(a => {
        if (!this.idxDone.includes(a.tour_pax.id)) {
          this.idxDone.push(+a.tour_pax.id);
        }
      });
    });
  }

  selectMult(que, opt) {
    // IDS
    if (!opt.checked && !que.listCheckedIds.includes('' + opt.id)) {
      que.listCheckedIds.push('' + opt.id);
    }
    if (opt.checked && que.listCheckedIds.includes('' + opt.id)) {
      const idx = que.listCheckedIds.findIndex(fi => '' + fi === '' + opt.id);
      if (idx >= 0) {
        que.listCheckedIds.splice(idx, 1);
      }
    }
    que.answer_ids = que.listCheckedIds.join(',');
  }

  submit() {
    const ans = [];
    this.data.form.questions.map(q => {
        // Only process if visible and no info
        if (q.visible && q.question_type?.type !== 10) {
          // text
          if (q.question_type?.type === 1) {
            q.answer_ids = '' + q.id;
          }
          // single
          if (q.question_type?.type === 2) {
            // if have normal answer, get ids
            if (q.answer) {
              q.answer_ids = '' + q.multiquestions.find(mq => '' + mq.option === '' + q.answer).id;
            }
            // user has written, force select it
            if (!q.answer && q.other) {
              const mqId = q.multiquestions.findIndex(mq => mq.option === null);
              q.answer = q.other;
              q.multiquestions[mqId].option = q.other;
              q.answer_ids = '' + q.multiquestions[mqId].id;
            }
            if (!q.answer && !q.other) {
              q.answer = null;
              q.answer_ids = null;
            }
          }
          // multiple
          if (q.question_type?.type === 3) {
            // process listCheckedIds
            // change ids to name into list Checked and into ans
            this.listChecked = [];
            const checkedIds = q.listCheckedIds.filter(i => q.multiquestions.some(mq => '' + mq.id === '' + i));
            checkedIds.forEach(id => {
              let name = q.multiquestions.find(mq => +mq.id === +id)?.option;
              if (!name) {
                name = q.other;
              }
              this.listChecked.push(name);
            });
            q.answer = (this.listChecked.length > 0) ? this.listChecked.join(', ') : null;
            q.answer_ids = (this.listChecked.length > 0) ? '' + checkedIds.join(',') : null;
          }
          // date
          if (q.question_type?.type === 4) {
            q.answer_ids = q.id;
            q.answer = null;
            if (this.dateTouch) {
              q.answer = this.datePipe.transform(this.date, 'yyyy-MM-dd');
            }
            q.answer_ids = '' + q.id;
          }
          // time
          if (q.question_type?.type === 5) {
            q.answer = null;
            if (this.timeTouch) {
              const today = new Date();
              const [hours, minutes] = this.time.split(':').map(Number);
              today.setHours(hours);
              today.setMinutes(minutes);
              q.answer = this.datePipe.transform(today, 'HH:mm');
            }
            q.answer_ids = '' + q.id;
          }

          // Check if required not answered
          if (q.required && !q.answer) {
            q.error = true;
            q.errMessage = 'Answer required';
            this.snackSvc.openSnackBar(q.errMessage);
            return;
          } else {
            q.error = false;
          }
          // Check if !multiple and that question already has answer = error
          if (!this.data.form.multiple && q.answers.length > 0 && ans.findIndex(a => a.id === q.id) >= 0) {
            q.error = true;
            q.errMessage = 'Only one response';
            this.snackSvc.openSnackBar(q.errMessage);
            return;
          }
          // ADD to answers
          ans.push({id: q.id, type: q.question_type.type, ans: q.answer ? q.answer : null, mq_ids: q.answer_ids ? q.answer_ids : null});
        }
      }
    );
    // console.log(ans);
    if (ans.every(a => !a.ans)) {
      this.errMessage = 'Empty form';
      return;
    }
    const errIDx = this.data.form.questions.findIndex(q => q.error);
    if (errIDx >= 0) {
      // this.toastSvc.presentToast(this.form.questions[errIDx].errMessage);
      return;
    }
    if (ans.length <= 0) {
      return;
    }
    this.data.responses = JSON.stringify(ans);
    this.onCreateAnswer();
  }

  onCreateAnswer() {
    this.loading = true;
    const data = {
      form_id: this.data.form?.id,
      tour_pax_id: this.data.tp_id,
      requester_id: this.data.user_id,
      responses: this.data.responses,
    };
    // console.log(data);
    this.httpForm.submitForm(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          this.loading = false;
          console.log(res);
          if (res.status < 400) {
            this.data.tp_id = null;
            this.data.form = res.results.form;
            this.onUpdate.emit(this.data);
            this.resetForm();
          } else {
            this.snackSvc.openSnackBar(res.message);
          }
        },
        error => {
          this.loading = false;
          console.log(error);
        });
  }

  close(reason) {
    this.data.reason = reason;
    this.dialogRef.close(this.data);
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
