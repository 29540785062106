import {Component, OnDestroy, OnInit} from '@angular/core';
import {Activetour} from '../../../../../../shared/models/activetour.model';
import {User} from '../../../../../../shared/models/user.model';
import {ActivetourService} from '../../../../../../shared/services/session/activetour.service';
import {SnackbarService} from '../../../../../../shared/services/common/snackbar.service';
import {takeUntil} from 'rxjs/operators';
import {AuthenticationService} from '../../../../../../shared/services/session/authentication.service';
import {Subject} from 'rxjs';
import {environment} from '../../../../../../../environments/environment';
import {Voucher} from '../../../../../../shared/models/voucher.model';
import {HttpTourService} from '../../../../../../shared/services/http/http-tour.service';
import {ConfirmationDialogComponent} from '../../../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {loadStripe} from '@stripe/stripe-js';
import {TitleService} from '../../../../../../shared/services/common/title.service';
import {Title} from '@angular/platform-browser';
import {HttpOrdersService} from '../../../../../../shared/services/http/http-orders.service';
import {FlagService} from '../../../../../../shared/services/common/flag.service';
import {TextTransformService} from '../../../../../../shared/helpers/texttransform.service';
import {DatePipe} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {NewtourService} from '../../services/newtour.service';

@Component({
  selector: 'app-summary-new-tour',
  templateUrl: './summary-new-tour.component.html',
  styleUrls: ['./summary-new-tour.component.scss']
})
export class SummaryNewTourComponent implements OnInit, OnDestroy {
  // Active tour info
  activeTour: Activetour;
  currentUser: User;
  voucher: Voucher;
  prod_id: string;
  returnUrl: string;
  refresh: string;
  baseUrlLogo: string;

  basic_includes: any[];
  plan_services: any[] = [];
  opt_includes: any[];
  stripePromise: any = null;
  stripe: any = null;

  firstLimit = 35;
  secondLimit = 70;
  firstPrice = 400;
  secondPrice = 300;
  thirdPrice = 200;

  ub = false;
  et = false;
  gy = false;
  ad = false;
  ga = false;

  tourYear: number;
  credit_left: number;
  tries_get_tour: number;
  tour_cost: number;
  amount_off: number;
  new_ppday: number;

  tour_paid: boolean;
  payment_loading = false;
  loading_components = false;
  pushed_pay = false;
  voucher_applied = false;
  show_services = false;
  show_comps = false;
  code_applicable = false;

  private onDestroy$ = new Subject<boolean>();

  constructor(
    private authSvc: AuthenticationService,
    private activeTourSvc: ActivetourService,
    private snackSvc: SnackbarService,
    private titleSvc: TitleService,
    private webTitleSvc: Title,
    private newTourSvc: NewtourService,
    public dialog: MatDialog,
    private flagService: FlagService,
    private textTransform: TextTransformService,
    private datePipe: DatePipe,
    private router: Router,
    private route: ActivatedRoute,
    private httpTour: HttpTourService,
    private httpOrder: HttpOrdersService,
  ) {
    this.authSvc.currentUser
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(user => {
        this.currentUser = user;
      });
    this.activeTourSvc.activeTour
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(tour => {
        this.activeTour = tour;
      });
    // Subscribe to the res$ observable to get the updated value
    this.newTourSvc.res$.subscribe((value) => {
      if (value && this.activeTour) {
        this.fillData(value);
      }
    });
    // Make sure to call `loadStripe` outside of a component’s render to avoid recreating the `Stripe` object on every render.
    this.stripePromise = loadStripe(environment.stripePublishKey)
      .then(res => {
        this.stripe = res;
      });
  }

  ngOnInit(): void {
    this.titleSvc.setTitle('Checkout');
    this.webTitleSvc.setTitle('Checkout | Planafy');
    this.prod_id = this.route.snapshot.params['tour-prodid'];
    this.baseUrlLogo = environment.baseUrl;
    this.voucher = new Voucher();
    this.basic_includes = [
      {icon: 'travel_explore', text: 'Itinerary with all components', enable: true},
      {icon: 'dynamic_form', text: 'Automatic rooming generator', enable: true},
      {icon: 'savings', text: 'Online tips payment', enable: true},
      {icon: 'notifications_active', text: 'Safety Check mail reminders', enable: true},
      {icon: 'share_location', text: 'Locate colleagues by city/date', enable: true},
      {icon: 'campaign', text: 'Incident report and notification', enable: true},
      // {icon: 'restaurant', text: 'Restaurants/clubs group deals', enable: true},
      // {icon: 'directions_bus', text: 'City cards for bus comments', enable: false},
      // {icon: 'thermostat', text: 'Daily weather info', enable: false},
      {icon: 'support', text: '7/7 technical support', enable: true},
      {icon: 'self_improvement', text: 'Peace of mind', enable: true},
      {icon: 'favorite', text: '... and much more!', enable: true},
    ];
    if (this.prod_id) {
      const tour = this.activeTourSvc.getTourUser(this.prod_id);
      if (tour) {
        this.activeTour.tour = tour;
        this.activeTourSvc.setActiveTour(this.activeTour);
        this.getTourData(tour.id);
      } else {
        this.router.navigate(['new']);
      }
    }
  }

  printIt() {
    console.log(this.activeTour);
  }

  fillData(res) {
    this.activeTour.tour = res.results.tour;
    this.activeTour.days = res.results.days;
    this.activeTour.itinerary = res.results.itinerary;
    this.activeTour.components = res.results.components;
    this.activeTour.events = res.results.events;
    this.activeTour.orders = res.results.orders;
    this.ub = this.activeTour.tour.company_id === 2;
    this.et = this.activeTour.tour.company_id === 3;
    this.ad = this.activeTour.tour.company_id === 4;
    this.gy = this.activeTour.tour.company_id === 5;
    this.ga = this.activeTour.tour.company_id === 6;
    const currentDate = new Date(this.activeTour.tour.tour_starts);
    this.tourYear = currentDate.getFullYear();
    this.currentUser.days_worked = res.results.days_worked;
    this.credit_left = res.results.credit / 100;
    const vouchers = res.results.vouchers;
    this.voucher_applied = vouchers.filter(item => item.tour_id === this.activeTour.tour.id && item.used_at).length > 0;
    this.code_applicable = this.voucher_applied;
    this.tour_paid = this.activeTour.orders.filter(o => o.status === 'Complete').length > 0;
    this.voucher = new Voucher();
    // Calculate new tour cost
    this.calcTourCost();
    if (this.tour_paid) {
      this.tour_cost = Math.floor(this.activeTour.orders.filter(o => o.status === 'Complete')[0].amount / 100);
    }
    const price_day = Math.floor((this.tour_cost / this.activeTour.tour.ndays) * 100);
    if (this.activeTour.tour.price_pday !== price_day) {
      this.activeTour.tour.price_pday = price_day;
      this.updateTour(this.activeTour.tour.price_pday);
    }
    this.activeTourSvc.setActiveTour(this.activeTour);
    this.onSelectFullPlan(vouchers.find(v => v.name.includes('HOLA-') && !v.used_at));
  }

  getTourData(id) {
    this.loading_components = true;
    this.currentUser.first_time = false;
    this.authSvc.updateUser(this.currentUser);
    this.httpTour.regenerateTour(id, this.currentUser.id, false, true)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            if (res.results.days.length <= 0 && this.tries_get_tour > 0) {
              console.log('Attempt #' + (4 - this.tries_get_tour));
              this.getTourData(id);
              this.tries_get_tour = this.tries_get_tour - 1;
            } else {
              this.loading_components = false;
            }
            if (this.tries_get_tour <= 0) {
              this.loading_components = false;
              this.snackSvc.openSnackBar('Timeout error. Please refresh page');
              return;
            }
            this.fillData(res);
          } else {
            this.loading_components = false;
            this.snackSvc.resultsElse(res);
            this.router.navigate(['new']);
          }
        },
        error => {
          this.loading_components = false;
          console.log(error);
          this.snackSvc.openSnackBar('Error. Contact support');
          this.flagService.setFlag(this.currentUser.id, this.router.url, 'Error regenerateTour()', JSON.stringify(error));
        });
  }

  calcTourCost() {
    if (this.et) {
      // Fully within limit: 40+15=55, all at 3€/day
      if (this.currentUser.days_worked + this.activeTour.tour.ndays <= this.secondLimit) {
        this.tour_cost = Math.floor(this.activeTour.tour.ndays * (this.secondPrice / 100));
      } else {
        //  First two cases: le 70 in between 60+15, 10 at 3€ and 5 at 2€/day
        if (this.currentUser.days_worked < this.secondLimit) {
          const days_s = (this.secondLimit - this.currentUser.days_worked);
          const days_t = (this.currentUser.days_worked + this.activeTour.tour.ndays) - this.secondLimit;
          this.tour_cost = Math.floor(days_s * (this.secondPrice / 100) + days_t * (this.thirdPrice / 100));
        } else {
          // If fully over: working days 74, all at 2€/day
          this.tour_cost = Math.floor(this.activeTour.tour.ndays * (this.thirdPrice / 100));
        }
      }
    } else if (this.ub || this.gy || this.ga || this.ad) {
      if (this.currentUser.days_worked + this.activeTour.tour.ndays <= this.firstLimit) {
        this.tour_cost = Math.floor(this.activeTour.tour.ndays * (this.firstPrice / 100));
      } else {
        // MORE THAN 35, four cases:
        //  First two cases: le 70 in between and fully
        if (this.currentUser.days_worked + this.activeTour.tour.ndays <= this.secondLimit) {
          if (this.currentUser.days_worked < this.firstLimit) {
            const days_f = (this.firstLimit - this.currentUser.days_worked);
            const days_s = (this.currentUser.days_worked + this.activeTour.tour.ndays) - this.firstLimit;
            this.tour_cost = Math.floor(days_f * (this.firstPrice / 100) + days_s * (this.secondPrice / 100));
          } else {
            // If fully over: working days 60
            this.tour_cost = Math.floor(this.activeTour.tour.ndays * (this.secondPrice / 100));
          }
          // Next two cases: gr 70 in between and fully
        } else {
          // From under 35 days to over 70
          if (this.currentUser.days_worked < this.firstLimit) {
            const days_f = (this.firstLimit - this.currentUser.days_worked);
            const days_s = (this.secondLimit - this.firstLimit - this.currentUser.days_worked);
            const days_t = (this.currentUser.days_worked + this.activeTour.tour.ndays) - this.secondLimit;
            this.tour_cost = Math.floor(days_f * (this.firstPrice / 100) + days_s * (this.secondPrice / 100) + days_t * (this.thirdPrice / 100));
            // From over 35 to over 70
          } else if (this.currentUser.days_worked >= this.firstLimit && this.currentUser.days_worked < this.secondLimit) {
            const days_s = (this.secondLimit - this.currentUser.days_worked);
            const days_t = (this.currentUser.days_worked + this.activeTour.tour.ndays) - this.secondLimit;
            this.tour_cost = Math.floor(days_s * (this.secondPrice / 100) + days_t * (this.thirdPrice / 100));
          } else {
            // If fully over: working days 75
            this.tour_cost = Math.floor(this.activeTour.tour.ndays * (this.thirdPrice / 100));
          }
        }
      }
    } else {
      this.tour_cost = Math.floor(this.activeTour.tour.ndays * (this.firstPrice / 100));
    }
  }

  async updateTour(ppd) {
    const params = {
      'req_id': this.currentUser.id,
      'price_pday': ppd,
    };
    const res = await this.httpTour.updateTourAsync(this.activeTour.tour.id, params);
    console.log(res);
    if (res.status < 400) {
      this.activeTour.tour = res.results.tour;
      this.activeTourSvc.setActiveTour(this.activeTour);
    } else {
      this.snackSvc.resultsElse(res);
    }
  }

// CODE
  voucherUpperCase() {
    this.voucher.name = this.voucher.name.toUpperCase();
  }

  onApplyCode() {
    if (this.tour_paid || this.payment_loading) {
      return;
    }
    if (!this.voucher.name) {
      this.code_applicable = false;
      return;
    }
    this.payment_loading = true;
    this.httpOrder.applyVoucher(this.currentUser.id, this.activeTour.tour.id, this.voucher.name)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(res => {
        console.log(res);
        if (res.status === 201) {
          setTimeout(() => {
            this.voucher = res.results.voucher;
            this.new_ppday = res.results.new_ppday;
            this.tour_cost = res.results.tour_cost / 100;
            this.amount_off = res.results.amount_off / 100;
            this.credit_left = res.results.credit / 100;
            this.voucher_applied = true;
          }, 10);
          if (+res.results.tour_cost === 0 || +res.results.new_ppday === 0) {
            this.calcTourCost();
            this.fakePayment();
          }
        } else {
          if (res.message === 'Error. Code used on that tour') {
            this.snackSvc.openSnackBar(res.message.toString());
          } else {
            this.voucher_applied = false;
            this.snackSvc.resultsElse(res);
          }
        }
        this.payment_loading = false;
      }, error => {
        this.payment_loading = false;
        console.log(error);
        this.snackSvc.openSnackBar('Error. Contact support');
        this.flagService.setFlag(this.currentUser.id, this.router.url, 'Error applyVoucher()', JSON.stringify(error));
      });
  }

  onUndoCode() {
    if (this.tour_paid) {
      return;
    }
    if (!this.voucher_applied) {
      this.code_applicable = false;
      return;
    }
    this.voucher_applied = false;
    this.payment_loading = true;
    const data = {
      'user_id': this.currentUser.id,
      'tour_id': this.activeTour.tour.id,
      'voucher_id': this.voucher.id,
    };
    this.httpOrder.undoVoucher(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(res => {
        console.log(res);
        if (res.status === 204) {
          this.new_ppday = res.results.new_ppday;
          this.tour_cost = res.results.tour_cost / 100;
          this.credit_left = res.results.credit / 100;
          this.voucher = new Voucher();
          this.calcTourCost();
        } else {
          this.snackSvc.resultsElse(res);
        }
        this.payment_loading = false;
      }, error => {
        this.payment_loading = false;
        console.log(error);
        this.snackSvc.openSnackBar('Error. Contact support');
        this.flagService.setFlag(this.currentUser.id, this.router.url, 'Error undoVoucher()', JSON.stringify(error));
      });
  }

  onSelectFullPlan(voucher?: Voucher) {
    this.voucher.name = null;
    if (voucher && voucher?.name?.substr(0, 5) === 'HOLA-') {
      this.voucher = voucher;
      this.code_applicable = true;
      this.onApplyCode();
    }
    this.plan_services = [
      {icon: 'auto_fix_normal', text: 'Self-written emails', enable: true},
      {icon: 'bolt', text: 'Quick update late-adds/cax', enable: true},
      {icon: 'description_outlined', text: 'Personalized templates', enable: true},
      {icon: 'link', text: 'Outlook mailbox integration', enable: true},
    ];
    this.plan_services.push(
      {icon: 'event_available', text: 'Daily schedule in Planafy app', enable: true},
      {icon: 'visibility', text: 'Customizable itinerary', enable: true},
      {icon: 'assistant', text: 'City free-time activities in app', enable: true},
    );
    this.opt_includes = [
      {icon: 'local_activity', text: 'Activities mail reconfirmation', enable: 'true'},
      {icon: 'pan_tool', text: 'PAX sign up on the app', enable: 'true'},
      {icon: 'shopping_cart', text: 'Online tour activity payment', enable: 'true'},
      {icon: 'price_check', text: 'Keep track of all payments', enable: 'true'},
    ];
  }

  // NAV
  goTour() {
    if (this.returnUrl && this.returnUrl !== 'undefined' && !this.returnUrl.includes('orders')) {
      if (this.refresh) {
        this.router.navigate([this.returnUrl], {queryParams: {ref: true}});
      } else {
        this.router.navigate([this.returnUrl]);
      }
    } else {
      this.activeTourSvc.setActiveTour(this.activeTour);
      this.router.navigate(['tour', this.activeTour.tour.prodid]);
    }
    setTimeout(() => {
      let top = document.getElementById('top');
      if (top) {
        top.scrollIntoView();
        top = null;
      }
    }, 10);
  }

  // PAYMENTS

  goPay() {
    this.createCheckout();
  }

  fakePayment() {
    this.pushed_pay = true;
    const data = {
      'user_id': this.currentUser.id,
      'tour_id': this.activeTour.tour.id,
      'amount': this.tour_cost * 100,
      'currency_code': 'EUR',
      'voucher_id': this.voucher.id,
    };
    this.httpOrder.makeFakePay(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status === 201) {
            this.tour_paid = true;
            this.tour_cost = 0;
            this.activeTour.tour.tour_settings.paid_at = this.datePipe.transform(new Date(), 'yyyy-MM-dd hh:mm:ss');
            this.activeTour.orders.push(res.results.orders);
            this.activeTourSvc.setActiveTour(this.activeTour);
            this.currentUser.days_worked += this.activeTour.tour.ndays;
            this.authSvc.updateUser(this.currentUser);
          } else {
            this.pushed_pay = false;
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              if (!res.message.error_description) {
                this.snackSvc.openSnackBar(res.message.message.toString());
              } else {
                this.snackSvc.openSnackBar(res.message.error_description.toString());
              }
            }
          }
        },
        error => {
          this.pushed_pay = false;
          console.log(error);
          this.snackSvc.openSnackBar('Error. Contact support');
          this.flagService.setFlag(this.currentUser.id, this.router.url, 'Error makeFakePay()', JSON.stringify(error));
        });
  }

  createCheckout() {
    this.pushed_pay = true;
    const data = {
      'user_id': this.currentUser.id,
      'tour_id': this.activeTour.tour.id,
      'amount': this.tour_cost * 100,
      'currency_code': 'EUR',
      'voucher_id': this.voucher.id ? this.voucher.id : null,
      'success_url': window.location.origin + '/tour/' + this.prod_id,
      'cancel_url': window.location.origin + '/orders/',
      'returnUrl': this.refresh ? this.returnUrl + '&ref=true' : this.returnUrl,
    };
    this.httpOrder.setStripeCheckout(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.activeTour.orders.push(res.results.orders);
            window.open(res.results.checkout_session.url, '_self');
          } else {
            this.pushed_pay = false;
            if (Array.isArray(res.results)) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else if (res.results.message) {
              this.snackSvc.openSnackBar(res.results.message);
            } else {
              if (!res.message.error_description) {
                this.snackSvc.openSnackBar(res.message.message.toString());
              } else {
                this.snackSvc.openSnackBar(res.message.error_description.toString());
              }
            }
          }
        },
        error => {
          this.pushed_pay = false;
          console.log(error);
          this.snackSvc.openSnackBar('Error. Contact support');
          this.flagService.setFlag(this.currentUser.id, this.router.url, 'Error setStripeCheckout(): ' + JSON.stringify(error), JSON.stringify(data));
        });
  }

  showPricing() {
    let confirmMessage;
    if (this.et) {
      confirmMessage = `EF Educational Tours:
          From <b>1</b> to <b>70</b> days worked: <b>3€</b>/day
          From <b>71+</b> days worked: <b>2€</b>/day`;
    } else if (this.ga) {
      confirmMessage = `EF Go Ahead Tours:
          From <b>1</b> to <b>35</b> days worked: <b>4€</b>/day
          From <b>36</b> to <b>70</b> days worked: <b>3€</b>/day
          From <b>71+</b> days worked: <b>2€</b>/day`;
    } else if (this.ub) {
      confirmMessage = `EF Ultimate Break:
          From <b>1</b> to <b>35</b> days worked: <b>4€</b>/day
          From <b>36</b> to <b>70</b> days worked: <b>3€</b>/day
          From <b>71+</b> days worked: <b>2€</b>/day`;
    } else if (this.gy) {
      confirmMessage = `EF Gap Year:
          From <b>1</b> to <b>35</b> days worked: <b>4€</b>/day
          From <b>36</b> to <b>70</b> days worked: <b>3€</b>/day
          From <b>71+</b> days worked: <b>2€</b>/day`;
    } else if (this.ad) {
      confirmMessage = `EF Adventures:
          From <b>1</b> to <b>35</b> days worked: <b>4€</b>/day
          From <b>36</b> to <b>70</b> days worked: <b>3€</b>/day
          From <b>71+</b> days worked: <b>2€</b>/day`;
    } else {
      confirmMessage = `<b>4€</b>/day`;
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      autoFocus: true,
      data: {
        disabled: false, confirmTitle: 'Price info', confirmMessage: confirmMessage
      }
    });
    dialogRef.afterClosed().subscribe(reason => {
      console.log(reason);
      if (reason) {
      } else {
      }
    });
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
