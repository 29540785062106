import {AfterViewInit, Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {User} from '../../../../shared/models/user.model';
import {map, startWith, takeUntil} from 'rxjs/operators';
import timezones from '../../../../../assets/json/tz.json';
import {Observable, Subject} from 'rxjs';
import {HttpCityService} from '../../../../shared/services/http/http-city.service';
import {City} from '../../../../shared/models/city.model';
import {AuthenticationService} from '../../../../shared/services/session/authentication.service';
import {SnackbarService} from '../../../../shared/services/common/snackbar.service';
import {Gsstype} from '../../../../shared/models/gsstype.model';
import {MatSort} from '@angular/material/sort';
import {AddCityComponent} from '../../../components/add-city/add-city.component';
import {TextTransformService} from '../../../../shared/helpers/texttransform.service';
import {MatDialog} from '@angular/material/dialog';
import {ExifOptions} from '@digitalascetic/ngx-pica/lib/ngx-pica-resize-options.interface';
import {NgxPicaErrorInterface, NgxPicaImageService, NgxPicaResizeOptionsInterface, NgxPicaService} from '@digitalascetic/ngx-pica';
import {environment} from '../../../../../environments/environment';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpImageService} from '../../../../shared/services/http/http-image.service';
import {Image} from '../../../../shared/models/image.model';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-adm-cities',
  templateUrl: './adm-cities.component.html',
  styleUrls: ['./adm-cities.component.scss']
})
export class AdmCitiesComponent implements OnInit, OnDestroy, AfterViewInit {
  currentUser: User;
  cities: City[] = [];
  citiesFiltered: City[] = [];
  gssTypes: Gsstype[];

  loading: boolean;
  searchText: string;
  searching = false;
  showSearch = false;
  showTimezones = false;
  baseUrl: string;
  filter: string;
  timezones: any;

  idxToCityEdit: any[] = [];

  control = new FormControl('');
  filteredTimezones: Observable<string[]>;

  @ViewChild(MatSort) sort: MatSort;

  private onDestroy$ = new Subject<boolean>();

  private static _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }

  constructor(
    public dialog: MatDialog,
    private httpCitySvc: HttpCityService,
    private textTransformService: TextTransformService,
    private ngxPicaService: NgxPicaService,
    private route: ActivatedRoute,
    private router: Router,
    private httpImageSvc: HttpImageService,
    private ngxPicaImageService: NgxPicaImageService,
    private authSvc: AuthenticationService,
    private snackSvc: SnackbarService,
  ) {
    this.authSvc.currentUser
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(user => {
        this.currentUser = user;
      });
    this.timezones = Object.keys(timezones);
  }

  @HostListener('window:keyup', ['$event'])
  showPinned(event: KeyboardEvent) {
    // console.log(event.key);
    event.preventDefault();
    if (event.key === 'Escape') {
      this.idxToCityEdit = [];
    }
  }

  ngAfterViewInit() {

  }

  private _filter(value: string): string[] {
    const filterValue = AdmCitiesComponent._normalizeValue(value);
    return this.timezones.filter(tz => AdmCitiesComponent._normalizeValue(tz).includes(filterValue));
  }

  selectTz(event, city) {
    city.timezone = event.option.value;
    this.onUpdateCity('timezone', city, {value: event.option.value});
    this.showTimezones = false;
    this.control.reset();
  }

  ngOnInit(): void {
    this.gssTypes = JSON.parse(localStorage.getItem('gssTypes'));
    this.baseUrl = environment.baseUrl;
    // Filtered Timezones
    this.filteredTimezones = this.control.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
    this.getAllCities();
  }

  filterBy(role?) {
    if (this.filter === role) {
      this.filter = null;
      this.citiesFiltered = this.cities;
    } else {
      if (role === 'confirmed') {
        this.citiesFiltered = this.cities.filter(c => !c[role]);
        this.filter = role;
      } else if (role === 'deleted') {
        this.citiesFiltered = this.cities.filter(c => c.deleted_at);
        this.filter = role;
      } else if (role === 'image') {
        this.citiesFiltered = this.cities.filter(c => !c.image);
        this.filter = role;
      } else if (role === 'coords') {
        this.citiesFiltered = this.cities.filter(c => !c.lat || !c.lon);
        this.filter = role;
      } else if (role === 'gss_image') {
        this.citiesFiltered = this.cities.filter(c => !c.gss_image);
        this.filter = role;
      } else if (role === 'sub_desc') {
        this.citiesFiltered = this.cities.filter(c => !c.sub_desc);
        this.filter = role;
      } else if (role === 'timezone') {
        this.citiesFiltered = this.cities.filter(c => !c.timezone);
        this.filter = role;
      } else {
        this.filter = null;
        this.citiesFiltered = this.cities;
      }
    }
  }

  addNewCity() {
    const dialogRef = this.dialog.open(AddCityComponent, {
      autoFocus: true,
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result['reason'] !== 'close') {
        const params = {
          'user_id': this.currentUser.id,
          'name': this.textTransformService.titleCase(result['name']),
          'country': this.textTransformService.titleCase(result['country']),
          'confirmed': true
        };
        this.httpCitySvc.saveCity(params)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(
            res => {
              console.log(res);
              if (res.status < 400) {
                const city = new City(res.results.city);
                const CityIdx = this.cities.findIndex(x => x.name === city.name && x.country === city.country);
                if (CityIdx < 0) {
                  this.cities.push(city);
                  localStorage.setItem('cities', JSON.stringify(this.cities));
                } else {
                  this.snackSvc.openSnackBar('City already exists');
                }
                this.cities.sort(function (a, b) {
                  return a.name[0].localeCompare(b.name[0]);
                });
              } else {
                this.snackSvc.resultsElse(res);
              }
            },
            error => {
              console.log(error);
            });
      }
    });
  }

  getAllCities() {
    this.loading = true;
    this.httpCitySvc.getCities()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status === 200) {
            // Get all users
            this.cities = res.results.cities;
            this.idxToCityEdit = [];
            this.filterBy();
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              console.log(res.message.toString());
            }
          }
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(error);
          this.snackSvc.openSnackBar('Error getting cities');
        });
  }

  onEditCity(city) {
    // console.log(city);
    if (!this.idxToCityEdit.includes(city.id)) {
      this.idxToCityEdit.push(city.id);
    }
  }

  onOkCity(city_id) {
    const removeIdx = this.idxToCityEdit.findIndex(i => '' + i === '' + city_id);
    this.idxToCityEdit.splice(removeIdx, 1);
  }

  uploadCityImage(city: City, event) {
    const elem = event.target;
    const file = elem.files[0];
    if (!this.ngxPicaImageService.isImage(file)) {
      this.snackSvc.openSnackBar('Error. Invalid image');
      return;
    }
    city.img_loading = true;
    if (elem.files.length > 0) {
      this.ngxPicaService.compressImage(file, 1024)
        .subscribe((imageCompressed: File) => {
          const exif: ExifOptions = {
            forceExifOrientation: true
          };
          const options: NgxPicaResizeOptionsInterface = {
            exifOptions: exif,
            alpha: true,
            aspectRatio: {keepAspectRatio: true, forceMinDimensions: true}
          };
          this.ngxPicaService.resizeImage(imageCompressed, 400, 300, options)
            .subscribe((imageResized: File) => {
              let newFile: File;
              if (file.type.includes('gif')) {
                newFile = new File([imageCompressed], file.name, {type: imageResized.type});
              } else {
                newFile = new File([imageResized], file.name, {type: imageResized.type});
              }
              const formData = new FormData();
              formData.append('file', newFile);
              formData.append('req_id', '' + this.currentUser.id);
              formData.append('city_id', '' + city.id);
              this.httpCitySvc.uploadCityImage(formData)
                .pipe(takeUntil(this.onDestroy$))
                .subscribe(
                  res => {
                    city.img_loading = false;
                    console.log(res);
                    if (res.status < 400) {
                      city.image = res.results.city.image;
                      city.files = res.results.city.files;
                    } else {
                      this.snackSvc.resultsElse(res);
                    }
                  },
                  error => {
                    city.img_loading = false;
                    console.log(error);
                  });
            }, (err: NgxPicaErrorInterface) => {
              city.img_loading = false;
              console.log('image not resized');
              console.log(err.err);
            });
        }, (err: NgxPicaErrorInterface) => {
          city.img_loading = false;
          console.log('image not compressed');
          console.log(err.err);
        });
      elem.value = '';
    }
  }

  uploadCityGSSImage(city: City, event) {
    const elem = event.target;
    const file = elem.files[0];
    if (!this.ngxPicaImageService.isImage(file)) {
      this.snackSvc.openSnackBar('Error. Invalid image');
      return;
    }
    city.gss_loading = true;
    if (elem.files.length > 0) {
      this.ngxPicaService.compressImage(file, 1024)
        .subscribe((imageCompressed: File) => {
          const exif: ExifOptions = {
            forceExifOrientation: true
          };
          const options: NgxPicaResizeOptionsInterface = {
            exifOptions: exif,
            alpha: true,
            aspectRatio: {keepAspectRatio: true, forceMinDimensions: true}
          };
          this.ngxPicaService.resizeImage(imageCompressed, 400, 300, options)
            .subscribe((imageResized: File) => {
              let newFile: File;
              if (file.type.includes('gif')) {
                newFile = new File([imageCompressed], file.name, {type: imageResized.type});
              } else {
                newFile = new File([imageResized], file.name, {type: imageResized.type});
              }
              const formData = new FormData();
              formData.append('file', newFile);
              formData.append('req_id', '' + this.currentUser.id);
              formData.append('city_id', '' + city.id);
              this.httpCitySvc.uploadCityGSSImage(formData)
                .pipe(takeUntil(this.onDestroy$))
                .subscribe(
                  res => {
                    city.gss_loading = false;
                    console.log(res);
                    if (res.status < 400) {
                      city.gss_image = res.results.city.gss_image;
                      city.files = res.results.city.files;
                    } else {
                      this.snackSvc.resultsElse(res);
                    }
                  },
                  error => {
                    city.gss_loading = false;
                    console.log(error);
                  });
            }, (err: NgxPicaErrorInterface) => {
              city.gss_loading = false;
              console.log('image not resized');
              console.log(err.err);
            });
        }, (err: NgxPicaErrorInterface) => {
          city.gss_loading = false;
          console.log('image not compressed');
          console.log(err.err);
        });
      elem.value = '';
    }
  }

  onUploadOtherImage(city: City, event) {
    const elem = event.target;
    const file = elem.files[0];
    if (!this.ngxPicaImageService.isImage(file)) {
      this.snackSvc.openSnackBar('Error. Invalid image');
      return;
    }
    city.other_loading = true;
    if (elem.files.length > 0) {
      this.ngxPicaService.compressImage(file, 1024)
        .subscribe((imageCompressed: File) => {
          const exif: ExifOptions = {
            forceExifOrientation: true
          };
          const options: NgxPicaResizeOptionsInterface = {
            exifOptions: exif,
            alpha: true,
            aspectRatio: {keepAspectRatio: true, forceMinDimensions: true}
          };
          this.ngxPicaService.resizeImage(imageCompressed, 400, 300, options)
            .subscribe((imageResized: File) => {
              let newFile: File;
              if (file.type.includes('gif')) {
                newFile = new File([imageCompressed], file.name, {type: imageResized.type});
              } else {
                newFile = new File([imageResized], file.name, {type: imageResized.type});
              }
              const formData = new FormData();
              formData.append('file', newFile);
              formData.append('file_name', city.img_file);
              formData.append('req_id', '' + this.currentUser.id);
              formData.append('city_id', '' + city.id);
              this.httpCitySvc.uploadOtherImage(formData)
                .pipe(takeUntil(this.onDestroy$))
                .subscribe(
                  res => {
                    city.other_loading = false;
                    console.log(res);
                    if (res.status < 400) {
                      const c_idx = this.citiesFiltered.findIndex(c => c.name === res.results.city.name);
                      this.citiesFiltered[c_idx].images = res.results.city.images;
                      this.citiesFiltered[c_idx].img_file = null;
                    } else {
                      this.snackSvc.resultsElse(res);
                    }
                  },
                  error => {
                    city.other_loading = false;
                    this.snackSvc.openSnackBar('Error uploading image');
                    console.log(error);
                  });
            }, (err: NgxPicaErrorInterface) => {
              city.other_loading = false;
              console.log('image not resized', err.err);
              this.snackSvc.openSnackBar('Error resizing image');
            });
        }, (err: NgxPicaErrorInterface) => {
          city.other_loading = false;
          console.log('image not compressed', err.err);
          this.snackSvc.openSnackBar('Error compressing image');
        });
      elem.value = '';
    }
  }

  setSunTimes() {
    this.loading = true;
    const data = {};
    this.httpCitySvc.setSunTimes(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.snackSvc.openSnackBar(res.message);
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              console.log(res.message.toString());
            }
          }
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(error);
          this.snackSvc.openSnackBar('Error setting suntimes');
        });
  }

  openImg(url: string) {
    window.open(this.baseUrl + url, '_blank');
  }

  openCoords(city) {
    window.open('https://www.google.com/maps?q=' + city.lat + ',' + city.lon, '_blank');
  }

  onUpdateCity(txt, city, e) {
    const data = {
      'req_id': this.currentUser.id
    };
    if (txt === 'local_transport') {
      city[txt] = !city[txt];
      data[txt] = city[txt];
    } else if (txt === 'confirmed') {
      data['confirmed'] = 1;
    } else if (txt === 'restore') {
      data['restore'] = true;
    } else if (txt === 'all') {
      data['name'] = city.name;
      data['country'] = city.country;
      data['gss_type_id'] = city.gss_type_id;
      data['confirmed'] = city.confirmed;
      data['lat'] = city.lat;
      data['lon'] = city.lon;
    } else if (txt === 'del_image') {
      data['image'] = null;
      data['del_image'] = true;
    } else if (txt === 'del_image_gss') {
      data['gss_image'] = null;
      data['del_image_gss'] = true;
    } else if (e && e.value) {
      data[txt] = e.value;
    } else if (e && e.target.value) {
      data[txt] = e.target.value;
    }
    // console.log(data);
    this.httpCitySvc.updateCity(city.id, data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            const removeIdx = this.idxToCityEdit.findIndex(i => '' + i === '' + city.id);
            this.idxToCityEdit.splice(removeIdx, 1);
            const idx = this.cities.findIndex(it => '' + it.id === '' + city.id);
            this.cities[idx] = res.results.city;
            if (this.filter) {
              this.citiesFiltered = this.cities.filter(it => !it[this.filter]);
            } else {
              this.citiesFiltered = this.cities;
            }
          } else {
            this.snackSvc.resultsElse(res);
            if (txt === 'local_transport') {
              city[txt] = !city[txt];
            }
          }
        },
        error => {
          console.log(error);
        });
  }

  onDeleteCity(city) {
    if (city.itinerary.length > 0) {
      this.snackSvc.openSnackBar('Error. ' + city.name + ' (' + city.id + ')' + ' on active on tour');
      return;
    }
    if (city.components_count > 0) {
      this.snackSvc.openSnackBar('Error. ' + city.name + ' (' + city.id + ')' + ' has components');
      return;
    }
    if (city.optionals_count > 0) {
      this.snackSvc.openSnackBar('Error. ' + city.name + ' (' + city.id + ')' + ' has activities');
      return;
    }
    if (city.activities_count > 0) {
      this.snackSvc.openSnackBar('Error. ' + city.name + ' (' + city.id + ')' + ' has activities');
      return;
    }
    this.httpCitySvc.deleteCity(city.id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(res => {
        console.log(res);
        if (res.status < 400) {
          city.deleted_at = true;
          const removeIdx = this.idxToCityEdit.findIndex(i => '' + i === '' + city.id);
          this.idxToCityEdit.splice(removeIdx, 1);
        } else {
          this.snackSvc.resultsElse(res);
        }
      }, error => {
        this.snackSvc.openSnackBar('Error deleting city ' + city.id);
        console.log(error);
      });
  }

  onForceDeleteCity(city) {
    // Show snackbar to undo delete
    const snackbarRef = this.snackSvc.openSnackBar('Force delete city ' + city.id + '?', 'OK');
    snackbarRef.afterDismissed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(reason => {
        // ACTION = OK
        if (reason.dismissedByAction) {
          this.forceDeleteCity(city);
        }
      });
  }

  forceDeleteCity(city) {
    this.httpCitySvc.deleteCity(city.id, 'true')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(res => {
        console.log(res);
        if (res.status < 400) {
          const Cidx = this.citiesFiltered.findIndex(it => '' + it.id === '' + city.id);
          this.citiesFiltered.splice(Cidx, 1);
        } else {
          this.snackSvc.resultsElse(res);
        }
      }, error => {
        this.snackSvc.openSnackBar('Error force deleting city ' + city.id);
        console.log(error);
      });
  }

  onDeleteImage(img: Image, city: City) {
    // Show snackbar to undo delete
    const snackbarRef = this.snackSvc.openSnackBar('Delete image?', 'OK');
    snackbarRef.afterDismissed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(reason => {
        // ACTION = OK
        if (reason.dismissedByAction) {
          this.httpImageSvc.deleteImage(img.id, this.currentUser.id)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(res => {
              console.log(res);
              if (res.status < 400) {
                const c_idx = this.citiesFiltered.findIndex(c => c.name === city.name);
                this.citiesFiltered[c_idx].images = this.citiesFiltered[c_idx].images.filter(i => +i.id !== +img.id);
                if (img && img.path.slice(img.path.length - 4) === '_gss') {
                  this.onUpdateCity('del_image_gss', city, null);
                }
                if (img && img.path.slice(img.path.length - 6) === '_cover') {
                  this.onUpdateCity('del_image', city, null);
                }
              } else {
                this.snackSvc.resultsElse(res);
              }
            }, error => {
              this.snackSvc.openSnackBar('Error deleting other image city ' + city.id);
              console.log(error);
            });
        }
      });
  }

  // SEARCH BARS
  searchRes(event: any): void {
    this.searching = event.target.value.length >= 1;
  }

  keyEscape() {
    if (this.searchText) {
      this.searchText = null;
    } else {
      this.showSearch = false;
    }
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
