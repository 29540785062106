import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {User} from '../../../../../shared/models/user.model';
import {Activetour} from '../../../../../shared/models/activetour.model';
import {Components} from '../../../../../shared/models/components.model';
import {SnackbarService} from '../../../../../shared/services/common/snackbar.service';
import {HttpTourService} from '../../../../../shared/services/http/http-tour.service';
import {Subject} from 'rxjs';
import {ActivetourService} from '../../../../../shared/services/session/activetour.service';
import {takeUntil} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../../../../../shared/services/session/authentication.service';
import {TitleService} from '../../../../../shared/services/common/title.service';
import {environment} from '../../../../../../environments/environment';
import {OutlookService} from '../../../../../shared/services/session/outlook.service';
import {Title} from '@angular/platform-browser';
import {NgxPicaErrorInterface, NgxPicaImageService, NgxPicaResizeOptionsInterface, NgxPicaService} from '@digitalascetic/ngx-pica';
import {HttpOrdersService} from '../../../../../shared/services/http/http-orders.service';
import {loadStripe} from '@stripe/stripe-js';
import {ExifOptions} from '@digitalascetic/ngx-pica/lib/ngx-pica-resize-options.interface';
import {HttpComponentService} from '../../../../../shared/services/http/http-component.service';
import {DupeTourComponent} from '../../../../components/tour/dupe-tour/dupe-tour.component';
import {MatDialog} from '@angular/material/dialog';
import {UpdatePaxComponent} from '../../../../components/tour/update-pax/update-pax.component';
import {Comptype} from '../../../../../shared/models/comptype.model';
import {UploadFileComponent} from '../../new-tour/components/upload-file/upload-file.component';
import {GrantAccessComponent} from '../../../../components/tour/grant-access/grant-access.component';
import {Clipboard} from '@angular/cdk/clipboard';
import {VersioningService} from '../../../../../shared/services/common/versioning.service';
import {TourService} from '../../../../../shared/services/common/tour.service';
import {BreakpointObserver} from '@angular/cdk/layout';

@Component({
  selector: 'app-tour-settings',
  templateUrl: './tour-settings.component.html',
  styleUrls: ['./tour-settings.component.scss']
})
export class TourSettingsComponent implements OnInit, OnDestroy {
  activeTour: Activetour;
  currentUser: User;
  payments: any[] = [];
  deleted_components: Components[] = [];
  untour_components: Components[] = [];
  returnUrl: string;
  linkURL: string;
  tipsURL: string;
  copyText: string;
  tooltip_upd: any;

  sum: string;
  company_name: string;
  baseUrlLogo: string;
  appUrl: string;
  beta = false;
  ub = false;
  et = false;
  gy = false;
  ad = false;
  ga = false;

  loading = false;
  loading_res = false;
  loading_img = false;
  showDeleted = false;
  showUntour = false;
  ok_copied1 = false;
  ok_copied2 = false;
  ok_copied3 = false;

  private onDestroy$ = new Subject<boolean>();
  pushed_pay = false;
  stripePromise: any = null;
  stripe: any = null;
  compTypes: Comptype[];

  @ViewChild('extras') searchElementExtras: ElementRef;

  constructor(
    private authSvc: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private titleSvc: TitleService,
    private webTitleSvc: Title,
    private clipboard: Clipboard,
    private tourSvc: TourService,
    private versioning: VersioningService,
    private httpOrders: HttpOrdersService,
    private httpComp: HttpComponentService,
    private ngxPicaService: NgxPicaService,
    private ngxPicaImageService: NgxPicaImageService,
    private outlookSvc: OutlookService,
    private httpTour: HttpTourService,
    private breakpointObserver: BreakpointObserver,
    private activeTourSvc: ActivetourService,
    private snackSvc: SnackbarService) {
    this.authSvc.currentUser
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(user => {
        this.currentUser = user;
      });
    this.activeTourSvc.activeTour
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(tour => {
        this.activeTour = tour;
        this.ub = tour?.tour.company_id === 2;
        this.et = tour?.tour.company_id === 3;
        this.ad = tour?.tour.company_id === 4;
        this.gy = tour?.tour.company_id === 5;
        this.ga = tour?.tour.company_id === 6;
      });
    this.titleSvc.setTitle(this.activeTour.tour.code + '#' + this.activeTour.tour.prodid);
    this.webTitleSvc.setTitle('Tour Settings | Planafy');
    // Make sure to call `loadStripe` outside of a component’s render to avoid recreating the `Stripe` object on every render.
    this.stripePromise = loadStripe(environment.stripePublishKey)
      .then(res => {
        this.stripe = res;
      });
    this.compTypes = JSON.parse(localStorage.getItem('compTypes'));
  }

  ngOnInit() {
    this.tooltip_upd = 'First, update PAX info above. Then, update late adds/caxed and "Select"';
    const prod_id = this.route.snapshot.params['tour-prodid'];
    if (this.activeTour.tour.prodid + '' !== prod_id) {
      this.snackSvc.openSnackBar('Error. Wrong tour');
      this.router.navigate(['tours']);
      return;
    }
    if (!this.activeTourSvc.belongsToUser(this.currentUser)) {
      this.snackSvc.openSnackBar('Error. Not your tour');
      this.router.navigate(['tours']);
      return;
    }
    this.showDeleted = !!this.route.snapshot.queryParams['del'] || false;
    if (this.showDeleted) {
      setTimeout(() => {
        this.searchElementExtras.nativeElement.scrollIntoView({behavior: 'smooth', block: 'nearest'});
      }, 333);
    }
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] ? this.route.snapshot.queryParams['returnUrl'] : null;
    this.beta = this.currentUser.email === 'beta@planafy.com';
    this.baseUrlLogo = environment.baseUrl;
    this.appUrl = environment.appUrl;
    const text0 = '*' + this.activeTour.tour.name + '*\n';
    const text1 = 'Follow the tour itinerary with the Planafy app!\n\n';
    const text2 = '\n1) Create account: Quick sign-up or register\n' +
      '2) At tour invite page click JOIN\n' +
      '3) Choose app store and download free app\n\n' +
      'Or download app, sign-up and use tour code: ' + this.activeTour.tour.prodid;
    this.linkURL = this.appUrl + 'join/' + btoa('' + this.activeTour.tour.id).replace(/=/g, '');
    this.copyText = text0 + text1 + this.linkURL + text2;
    this.tipsURL = environment.selfUrl + 'appreciation/' + btoa('' + this.activeTour.tour.id);
    this.getTourData(this.activeTour.tour.id);
  }

  numericOnly(e) {
    return e.key !== '+' && e.key !== '-' && e.key !== 'e' && e.key !== 'E' && e.key !== '.' && e.key !== ',';
  }

  onModifyTemplate() {
    this.router.navigate(['user', 'templates'], {queryParams: {returnUrl: window.location.pathname, temp: 'Quick updates'}});
  }

  goAdminComp(c_id) {
    const url = this.router.serializeUrl(this.router.createUrlTree(['admin', 'comps'], {queryParams: {c: c_id}}));
    window.open(url, '_blank');
  }

  goComp(comp) {
    this.router.navigate(['tour/' + this.activeTour.tour.prodid + '/day/' + comp.day_id + '/component/' + comp.id], {queryParams: {edit: true}});
  }

  onGoItinerary() {
    this.router.navigate(['itinerary', this.activeTour.tour.prodid]);
  }

  openLink(linkURL) {
    window.open(linkURL, '_blank');
  }

  /*
  /* HTTP REQUESTS
  *******************/
  async getTourData(id) {
    this.loading = true;
    const res = await this.httpTour.getTourAllAsync(id, 2);
    console.log(res);
    if (res.status < 400) {
      this.versioning.check(res.results.version);
      this.activeTour.tour = res.results.tour;
      this.activeTour.days = res.results.days;
      this.activeTour.itinerary = res.results.itinerary;
      this.activeTour.components = res.results.components;
      this.activeTour.optionals = res.results.optionals;
      this.activeTour.events = res.results.events;
      this.activeTour.orders = res.results.orders;
      this.ub = this.activeTour.tour.company_id === 2;
      this.et = this.activeTour.tour.company_id === 3;
      this.ad = this.activeTour.tour.company_id === 4;
      this.gy = this.activeTour.tour.company_id === 5;
      this.ga = this.activeTour.tour.company_id === 6;
      this.activeTourSvc.setActiveTour(this.activeTour);
      this.sum = res.results.sum;
      this.payments = this.activeTour.orders.filter(o => o.status === 'Complete');
      this.deleted_components = res.results.components_deleted;
      this.untour_components = res.results.components_untour;
      this.untour_components.map(c => c.comp_type = this.compTypes.find(ct => +ct.id === +c.comp_type_id));
      this.company_name = this.activeTour.tour.company.full_name;
      if (!localStorage.getItem('folder_id')) {
        this.tourSvc.onCreateTourFolder(false);
      }
      this.loading = false;
    } else {
      this.loading = false;
      this.snackSvc.resultsElse(res);
      this.router.navigate(['tours']);
    }
  }

  onRefreshTS() {
    this.getTourData(this.activeTour.tour.id);
  }

  uploadLogoFile(event) {
    const elem = event.target;
    const file = elem.files[0];
    if (!this.ngxPicaImageService.isImage(file)) {
      this.snackSvc.openSnackBar('Error. Invalid image');
      return;
    }
    if (elem.files.length > 0) {
      this.loading = true;
      this.ngxPicaService.compressImage(file, 512)
        .subscribe((imageCompressed: File) => {
          const exif: ExifOptions = {
            forceExifOrientation: true
          };
          const options: NgxPicaResizeOptionsInterface = {
            exifOptions: exif,
            alpha: true,
            aspectRatio: {keepAspectRatio: true, forceMinDimensions: true}
          };
          this.ngxPicaService.resizeImage(imageCompressed, 200, 150, options)
            .subscribe((imageResized: File) => {
              const newFile: File = new File([imageResized], file.name, {type: imageResized.type});
              const formData = new FormData();
              formData.append('file', newFile);
              formData.append('req_id', '' + this.currentUser.id);
              formData.append('tour_id', '' + this.activeTour.tour.id);
              this.httpTour.sendLogo(formData)
                .pipe(takeUntil(this.onDestroy$))
                .subscribe(
                  res => {
                    console.log(res);
                    if (res.status < 400) {
                      this.activeTour.tour.logo = res.results.tour.logo;
                      this.activeTourSvc.setActiveTour(this.activeTour);
                    } else {
                      this.snackSvc.resultsElse(res);
                    }
                    this.loading = false;
                  },
                  error => {
                    this.loading = false;
                    console.log(error);
                    this.snackSvc.openSnackBar('ERROR. Resizing image');
                  });

            }, (err: NgxPicaErrorInterface) => {
              this.loading = false;
              console.log('company logo not resized');
              console.log(err.err);
            });

        }, (err: NgxPicaErrorInterface) => {
          this.loading = false;
          console.log('company logo not compressed');
          console.log(err.err);
        });
      elem.value = '';
    }
  }

  uploadTourCover(event) {
    const elem = event.target;
    const file = elem.files[0];
    if (!this.ngxPicaImageService.isImage(file)) {
      this.snackSvc.openSnackBar('Error. Invalid image');
      return;
    }
    if (elem.files.length > 0) {
      this.loading = true;
      this.ngxPicaService.compressImage(file, 1024)
        .subscribe((imageCompressed: File) => {
          const exif: ExifOptions = {
            forceExifOrientation: true
          };
          const options: NgxPicaResizeOptionsInterface = {
            exifOptions: exif,
            alpha: true,
            aspectRatio: {keepAspectRatio: true, forceMinDimensions: true}
          };
          this.ngxPicaService.resizeImage(imageCompressed, 0, 250, options)
            .subscribe((imageResized: File) => {
              const newFile: File = new File([imageResized], file.name, {type: imageResized.type});
              const formData = new FormData();
              formData.append('file', newFile);
              formData.append('req_id', '' + this.currentUser.id);
              formData.append('tour_id', '' + this.activeTour.tour.id);
              this.httpTour.sendCover(formData)
                .pipe(takeUntil(this.onDestroy$))
                .subscribe(
                  res => {
                    console.log(res);
                    if (res.status < 400) {
                      this.activeTour.tour.cover = res.results.tour.cover;
                      this.activeTourSvc.setActiveTour(this.activeTour);
                    } else {
                      this.snackSvc.resultsElse(res);
                    }
                    this.loading = false;
                  },
                  error => {
                    this.loading = false;
                    console.log(error);
                    this.snackSvc.openSnackBar('ERROR. Uploading tour cover');
                  });

            }, (err: NgxPicaErrorInterface) => {
              this.loading = false;
              console.log('tour cover not resized');
              console.log(err.err);
            });

        }, (err: NgxPicaErrorInterface) => {
          this.loading = false;
          console.log('tour cover not compressed');
          console.log(err.err);
        });
      elem.value = '';
    }
  }

  uploadGroupPic(event) {
    const elem = event.target;
    const file = elem.files[0];
    if (!this.ngxPicaImageService.isImage(file)) {
      this.snackSvc.openSnackBar('Error. Invalid image');
      return;
    }
    if (elem.files.length > 0) {
      this.loading_img = true;
      this.ngxPicaService.compressImage(file, 1024)
        .subscribe((imageCompressed: File) => {
          const exif: ExifOptions = {
            forceExifOrientation: true
          };
          const options: NgxPicaResizeOptionsInterface = {
            exifOptions: exif,
            alpha: true,
            aspectRatio: {keepAspectRatio: true, forceMinDimensions: true}
          };
          this.ngxPicaService.resizeImage(imageCompressed, 0, 800, options)
            .subscribe((imageResized: File) => {
              const newFile: File = new File([imageResized], file.name, {type: imageResized.type});
              const formData = new FormData();
              formData.append('file', newFile);
              formData.append('req_id', '' + this.currentUser.id);
              formData.append('tour_id', '' + this.activeTour.tour.id);
              this.httpTour.sendGroupPic(formData)
                .pipe(takeUntil(this.onDestroy$))
                .subscribe(
                  res => {
                    console.log(res);
                    if (res.status < 400) {
                      this.activeTour.tour.tour_settings.group_picture = res.results.tour_settings.group_picture;
                      this.activeTourSvc.setActiveTour(this.activeTour);
                    } else {
                      this.snackSvc.resultsElse(res);
                    }
                    this.loading_img = false;
                  },
                  error => {
                    this.loading_img = false;
                    console.log(error);
                    this.snackSvc.openSnackBar('ERROR. Uploading group picture');
                  });

            }, (err: NgxPicaErrorInterface) => {
              this.loading_img = false;
              console.log('group pic not resized');
              console.log(err.err);
            });

        }, (err: NgxPicaErrorInterface) => {
          this.loading_img = false;
          console.log('group pic not compressed');
          console.log(err.err);
        });
      elem.value = '';
    }
  }

  onForceDeleteComponent(id) {
    this.httpComp.deleteComponent(id, this.currentUser.id, 'true')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status === 204) {
            this.deleted_components = this.deleted_components.filter(dc => dc.id !== id);
            if (this.deleted_components.length === 0) {
              this.showDeleted = false;
            }
            // If dismiss or no action delete the component + event from memory
          } else {
            // Error deleting city component in DB but we remove it anyway from memory
            this.snackSvc.resultsElse(res);
          }
        },
        error => {
          console.log(error);
          this.snackSvc.openSnackBar('ERROR. Force deleting component');
        });
  }

  restoreComp(id) {
    this.loading = true;
    const data = {
      'restore': true,
    };
    this.httpComp.updateComponent(id, data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            const comIdx = this.activeTour.components.findIndex(it => '' + it.id === '' + id);
            this.activeTour.components[comIdx] = res.results.component;
            this.activeTourSvc.setActiveTour(this.activeTour);
            this.deleted_components = this.deleted_components.filter(dc => dc.id !== id);
            if (this.deleted_components.length === 0) {
              this.showDeleted = false;
            }
          } else {
            console.log('Error: component not restored');
            this.snackSvc.resultsElse(res);
          }
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(error);
        });
  }

  restoreAllComps() {
    // Show snackbar to undo delete
    const snackbarRef = this.snackSvc.openSnackBar('Restore components?', 'OK');
    snackbarRef.afterDismissed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(reason => {
        // ACTION = OK
        if (reason.dismissedByAction) {
          this.loading = true;
          this.httpComp.restoreDeletedComponents(this.activeTour.tour.id)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(
              res => {
                console.log(res);
                if (res.status < 400) {
                  this.deleted_components = [];
                  this.showDeleted = false;
                } else {
                  console.log('Error: components not restored');
                  this.snackSvc.resultsElse(res);
                }
                this.loading = false;
              },
              error => {
                this.loading = false;
                console.log(error);
              });
        }
      });
  }

  trashAllComps() {
    // Show snackbar to undo delete
    const snackbarRef = this.snackSvc.openSnackBar('Empty components?', 'OK');
    snackbarRef.afterDismissed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(reason => {
        // ACTION = OK
        if (reason.dismissedByAction) {
          this.loading = true;
          this.httpComp.forceDeletedComponents(this.activeTour.tour.id)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(
              res => {
                console.log(res);
                if (res.status < 400) {
                  this.deleted_components = [];
                  this.showDeleted = false;
                } else {
                  console.log('Error: components not emptied');
                  this.snackSvc.resultsElse(res);
                }
                this.loading = false;
              },
              error => {
                this.loading = false;
                console.log(error);
              });
        }
      });
  }

  deleteLogo() {
    this.activeTour.tour.logo = '';
    this.updateTour([{'key': 'logo', 'value': ''}, {'key': 'del_logo', 'value': true}]);
  }

  deleteCover() {
    this.activeTour.tour.cover = '';
    this.updateTour([{'key': 'cover', 'value': ''}, {'key': 'del_cover', 'value': true}]);
  }

  deleteGroupPic() {
    this.activeTour.tour.tour_settings.group_picture = '';
    this.updateTour([{'key': 'del_group_pic', 'value': true}]);
  }

  checkVisibility() {
    if (!this.activeTour.tour.tour_settings.visible) {
      this.publishTour();
    } else if (this.activeTour.tour.tour_settings.visible) {
      this.unPublishTour();
    } else {
      return;
    }
  }

  publishTour() {
    if (this.loading) {
      this.snackSvc.openSnackBar('Wait loading content');
      return;
    }
    if (this.activeTour.days.length < 1 || this.activeTour.itinerary.length < 1) {
      const snackbarRef = this.snackSvc.openSnackBar('Provide an itinerary', 'GO');
      snackbarRef.afterDismissed()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(reason => {
          if (reason.dismissedByAction) {
            this.router.navigate(['itinerary', this.activeTour.tour.prodid], {queryParams: {returnUrl: window.location.pathname}});
          }
        });
      return;
    }
    if (this.payments.length <= 0) {
      const snackbarRef = this.snackSvc.openSnackBar('Purchase to publish tour', 'GO');
      snackbarRef.afterDismissed()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(reason => {
          if (reason.dismissedByAction) {
            this.goPayTour();
          }
        });
    } else {
      this.activeTour.tour.tour_settings.visible = 1;
      this.updateTour([{'key': 'visible', 'value': 1}]);
    }
  }

  goPayTour() {
    this.router.navigate(['summary', this.activeTour.tour.prodid], {queryParams: {returnUrl: window.location.pathname}});
  }

  unPublishTour() {
    this.activeTour.tour.tour_settings.visible = 0;
    this.updateTour([{'key': 'visible', 'value': 0}]);
  }

  checkTips() {
    if (!this.activeTour.tour.tour_settings.enable_tips) {
      this.enableTips();
    } else if (this.activeTour.tour.tour_settings.enable_tips) {
      this.disableTips();
    } else {
      return;
    }
  }

  enableTips() {
    this.activeTour.tour.tour_settings.enable_tips = 1;
    this.updateTour([{'key': 'enable_tips', 'value': 1}]);
  }

  disableTips() {
    this.activeTour.tour.tour_settings.enable_tips = 0;
    this.updateTour([{'key': 'enable_tips', 'value': 0}]);
  }

  changeCurr(txt) {
    this.activeTour.tour.tour_settings.tips_currency = txt;
    this.updateTour([{'key': 'tips_currency', 'value': txt}]);
  }

  copyItin() {
    if (this.currentUser.role > 9) {
      this.clipboard.copy(this.sum.replace(/\\n/g, '\n').replace('"', ''));
      this.notify('Itin copied');
    }
  }

  notify(txt?) {
    if (txt === '1') {
      this.ok_copied1 = true;
      setTimeout(() => {
        this.ok_copied1 = false;
      }, 2000);
    } else if (txt === '2') {
      this.ok_copied2 = true;
      this.snackSvc.openSnackBar('Instructions copied');
      setTimeout(() => {
        this.ok_copied2 = false;
      }, 2000);
    } else if (txt === '3') {
      this.ok_copied3 = true;
      setTimeout(() => {
        this.ok_copied3 = false;
      }, 2000);
    } else if (txt) {
      this.snackSvc.openSnackBar(txt);
    } else {
      this.snackSvc.openSnackBar('Copied');
    }
  }

  updateTour(values: any) {
    this.loading = true;
    const params = {
      'req_id': this.currentUser.id,
    };
    for (const i of values) {
      if (i.value === null) {
        params[i.key] = null;
      } else {
        params[i.key] = '' + i.value;
      }
    }
    // console.log(params);
    this.httpTour.updateTour(this.activeTour.tour.id, params)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(res => {
        console.log(res);
        if (res.status === 203) {
          this.activeTour.tour = res.results.tour;
          this.activeTourSvc.setActiveTour(this.activeTour);
          this.loading = false;
        } else {
          this.snackSvc.resultsElse(res);
        }
      }, error => {
        this.loading = false;
        console.log(error);
        this.snackSvc.openSnackBar('ERROR. Updating tour');
      });
  }

  /* STRIPE */

  createCheckout() {
    const data1 = {
      'user_id': this.currentUser.id,
      'tour_id': this.activeTour.tour.id,
      'tour_cost': this.activeTour.tour.ndays * 100,
      'name': 'UPGRADE',
      'silent': true
    };
    this.httpOrders.storeVoucher(data1)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(res1 => {
        console.log(res1);
        if (res1.status < 400) {
          this.pushed_pay = true;
          const data = {
            'user_id': this.currentUser.id,
            'tour_id': this.activeTour.tour.id,
            'amount': this.activeTour.tour.ndays * 100,
            'currency_code': 'EUR',
            'voucher_id': res1.results.voucher.id,
            'success_url': window.location.origin + '/tour/' + this.activeTour.tour.prodid,
            'cancel_url': window.location.origin + '/orders/',
            'returnUrl': this.returnUrl,
          };
          this.httpOrders.setStripeCheckout(data)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(
              res => {
                console.log(res);
                if (res.status < 400) {
                  this.activeTour.orders.push(res.results.orders);
                  window.open(res.results.checkout_session.url, '_self');
                } else {
                  this.pushed_pay = false;
                  if (res.results) {
                    this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
                  } else {
                    if (!res.message.error_description) {
                      this.snackSvc.openSnackBar(res.message.message.toString());
                    } else {
                      this.snackSvc.openSnackBar(res.message.error_description.toString());
                    }
                  }
                }
              },
              error => {
                this.pushed_pay = false;
                console.log(error);
                this.snackSvc.openSnackBar('ERROR. Setting up checkout');
              });
        } else {
          if (res1.results) {
            this.snackSvc.openSnackBar(res1.results[Object.keys(res1.results)[0]].toString());
          } else {
            this.snackSvc.openSnackBar(res1.message.toString());
          }
        }
      }, error => {
        console.log(error);
        this.snackSvc.openSnackBar('ERROR. Creating checkout');
      });
  }

  goOutlook() {
    const tf = localStorage.getItem('folder_id');
    if (tf) {
      window.open('https://outlook.office.com/mail/'
        + encodeURIComponent(tf)
          .replace(/-/g, '%2F')
          .replace(/_/g, '%2B'), '_blank');
    } else {
      this.snackSvc.openSnackBar('Creating tour folder, try again');
      this.tourSvc.onCreateTourFolder(false);
    }
  }

  resetTourFolder() {
    localStorage.removeItem('folder_id');
    this.tourSvc.onCreateTourFolder(true);
  }

  goToPaxInfo() {
    this.router.navigate(['pax', this.activeTour.tour.prodid], {queryParams: {returnUrl: window.location.pathname}});
  }

  goBack() {
    if (this.returnUrl && this.returnUrl !== 'undefined') {
      this.router.navigate([this.returnUrl]);
    } else {
      this.activeTourSvc.setActiveTour(this.activeTour);
      this.router.navigate(['tour', this.activeTour.tour.prodid]);
    }
  }

  sendLateAdds() {
    if (this.activeTour.tour.nlated >= 0) {
      this.updateTour([{'key': 'nlated', 'value': this.activeTour.tour.nlated}]);
      this.onChooseMails('lated');
    } else {
      this.snackSvc.openSnackBar('Error. Invalid number');
      return;
    }
  }

  sendCaxed() {
    if (this.activeTour.tour.ncaxed >= 0) {
      this.updateTour([{'key': 'ncaxed', 'value': this.activeTour.tour.ncaxed}]);
      this.onChooseMails('caxed');
    } else {
      this.snackSvc.openSnackBar('Error. Invalid number');
      return;
    }
  }

  sendDiests() {
    this.onChooseMails('diets');
  }

  onChooseMails(type) {
    if (this.currentUser.view_as) {
      this.snackSvc.openSnackBar('Not in View as');
      return;
    }
    if (type === 'lated' && this.activeTour.tour.nlated < 1) {
      this.snackSvc.openSnackBar('Set a number of late adds');
      return;
    }
    if (type === 'caxed' && this.activeTour.tour.ncaxed < 1) {
      this.snackSvc.openSnackBar('Set a number of cancels');
      return;
    }
    this.activeTour.components.map(c => c.comp_type = this.compTypes.find(ct => ct.id === c.comp_type_id));
    let comps;
    if (type === 'lated' || type === 'caxed') {
      comps = this.activeTour.components.filter(c => c.sent_at !== null && c.recipients);
    } else if (type === 'diets') {
      comps = this.activeTour.components.filter(c => c.sent_at !== null && c.recipients && (c.comp_type.type === 5 || c.requests?.includes('{di}')));
    } else {
      this.snackSvc.openSnackBar('Not contemplated');
      return;
    }
    const dialogRef = this.dialog.open(UpdatePaxComponent, {
      minWidth: '50%',
      maxHeight: '90vh',
      data: {
        'tour': this.activeTour.tour,
        'components': comps,
        'user_id': this.currentUser.id,
        'secs_undo': this.currentUser.user_settings.secs_undo,
        'type': type
      }
    });
    if (type !== 'diets') {
      dialogRef.afterClosed().subscribe(result => {
        if (result && result !== 'close') {
          this.activeTour.tour.nlated = 0;
          this.activeTour.tour.ncaxed = 0;
          this.updateTour([{'key': 'n' + type, 'value': 0}]);
          return;
        } else {
          // console.log('Didnt send messages');
        }
      });
    }
  }

  private getDialogWidths() {
    if (this.breakpointObserver.isMatched('(max-width: 575px)')) {
      return {minWidth: '92vw', maxWidth: '92vw'}; // xs
    } else if (this.breakpointObserver.isMatched('(max-width: 767px)')) {
      return {minWidth: '85vw', maxWidth: '90vw'}; // sm
    } else if (this.breakpointObserver.isMatched('(max-width: 991px)')) {
      return {minWidth: '60vw', maxWidth: '70vw'}; // md
    } else if (this.breakpointObserver.isMatched('(max-width: 1199px)')) {
      return {minWidth: '50vw', maxWidth: '50vw'}; // lg
    } else {
      return {minWidth: '40vw', maxWidth: '40vw'}; // xl
    }
  }

  // Upload TDI
  onAddTDI() {
    const dialogRef = this.dialog.open(UploadFileComponent, {
      ...this.getDialogWidths(),
      autoFocus: true,
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result['reason'] === 'save') {
        // console.log('ok');
      } else {
        console.log('Didnt ok');
      }
    });
  }

  // Duplicate tour
  onDuplicateTour() {
    const dialogRef = this.dialog.open(DupeTourComponent, {
      autoFocus: true,
      data: {'tour_id': this.activeTour.tour.id, 'user_id': this.currentUser.id}
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
      if (result && result['reason'] !== 'close') {
        this.router.navigate(['tours']);
        return;
      } else {
        // console.log('Didnt duplicate tour');
      }
    });
  }

  // Grant access
  onGrantAccess() {
    const dialogRef = this.dialog.open(GrantAccessComponent, {
      minHeight: '20vh',
      autoFocus: true,
      data: {'tour': this.activeTour.tour, 'user': this.currentUser, 'tour_access': this.activeTour.tour.tour_access}
    });
    dialogRef.componentInstance.deleteTA.subscribe(i => {
      const idx = this.activeTour.tour.tour_access.findIndex(t => +t.id === +i);
      this.activeTour.tour.tour_access.splice(idx, 1);
      this.activeTourSvc.setActiveTour(this.activeTour);
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
      if (result && result['reason'] !== 'close') {
        return;
      } else {
        // console.log('Didnt grant access');
      }
      if (!this.activeTourSvc.belongsToUser(this.currentUser)) {
        this.snackSvc.openSnackBar('Error. Not your tour?');
        this.router.navigate(['tours']);
        return;
      }
    });
  }

  // Restore given tour
  onRegenerateTour() {
    // Show snackbar to undo restore
    const snackbarRef = this.snackSvc.openSnackBar('Restore everything!?', 'OK');
    snackbarRef.afterDismissed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(reason => {
        if (reason.dismissedByAction) {
          setTimeout(() => {
            this.loading_res = true;
          }, 1);
          this.httpTour.regenerateTour(this.activeTour.tour.id, this.currentUser.id, true, false)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(
              res => {
                console.log(res);
                setTimeout(() => {
                  this.loading_res = false;
                }, 1);
                if (res.status === 204) {
                  this.router.navigate(['tours']);
                }
              },
              error => {
                setTimeout(() => {
                  this.loading_res = false;
                }, 1);
                console.log(error);
                this.snackSvc.openSnackBar('ERROR. Regenerating tour');
              });
        }
      });
  }

  // Publish free times
  onPublishFreeTimes() {
    if (this.payments.length <= 0) {
      const snackbarRef = this.snackSvc.openSnackBar('Purchase to publish', 'GO');
      snackbarRef.afterDismissed()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(reason => {
          if (reason.dismissedByAction) {
            this.goPayTour();
          }
        });
    } else {
      // Show snackbar to undo restore
      const snackbarRef = this.snackSvc.openSnackBar('Publish Free Times?', 'OK');
      snackbarRef.afterDismissed()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(reason => {
          if (reason.dismissedByAction) {
            const data = {
              'tour_id': this.activeTour.tour.id,
              'publish': true
            };
            this.httpComp.publishFreeTimes(data)
              .pipe(takeUntil(this.onDestroy$))
              .subscribe(
                res => {
                  console.log(res);
                  if (res.status < 400) {
                    // this.snackSvc.openSnackBar('Free times published');
                  }
                },
                error => {
                  console.log(error);
                  this.snackSvc.openSnackBar('ERROR. Publishing free times');
                });
          }
        });
    }
  }

  // Publish everything
  onPublishAll() {
    if (this.payments.length <= 0) {
      const snackbarRef = this.snackSvc.openSnackBar('Purchase to publish', 'GO');
      snackbarRef.afterDismissed()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(reason => {
          if (reason.dismissedByAction) {
            this.goPayTour();
          }
        });
    } else {
      // Show snackbar to undo restore
      const snackbarRef = this.snackSvc.openSnackBar('Publish everything?', 'OK');
      snackbarRef.afterDismissed()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(reason => {
          if (reason.dismissedByAction) {
            const data = {
              'tour_id': this.activeTour.tour.id,
            };
            this.httpComp.publishEverything(data)
              .pipe(takeUntil(this.onDestroy$))
              .subscribe(
                res => {
                  console.log(res);
                  if (res.status < 400) {
                    // this.snackSvc.openSnackBar('Free times published');
                  }
                },
                error => {
                  console.log(error);
                  this.snackSvc.openSnackBar('ERROR. Publishing everything');
                });
          }
        });
    }
  }

  // Export
  onExportTour() {
    if (this.payments.length <= 0) {
      const snackbarRef = this.snackSvc.openSnackBar('Purchase to export tour', 'GO');
      snackbarRef.afterDismissed()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(reason => {
          if (reason.dismissedByAction) {
            this.goPayTour();
          }
        });
    } else {
      this.httpTour.exportTour(this.activeTour.tour.id)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          x => {
            const newBlob = new Blob([x], {type: 'application/txt'});
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob);
            const link = document.createElement('a');
            link.href = data;
            link.download = 'Planafy_' + this.activeTour.tour.code + '.' + this.activeTour.tour.prodid + '.txt';
            // this is necessary as link.click() does not work on the latest firefox
            link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
            setTimeout(function () {
              // For Firefox it is necessary to delay revoking the ObjectURL
              window.URL.revokeObjectURL(data);
              link.remove();
            }, 100);
            this.loading = false;
          },
          error => {
            this.loading = false;
            console.log(error);
            this.snackSvc.openSnackBar('Error exporting tour');
          });
    }

  }

  // Delete given tour
  onDeleteTour() {
    // Show snackbar to undo delete
    const snackbarRef = this.snackSvc.openSnackBar('Delete tour!?', 'OK');
    snackbarRef.afterDismissed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(reason => {
        if (reason.dismissedByAction) {
          // If dismiss or no action with snackbar (undo) delete the tour folder
          this.httpTour.deleteTour(this.activeTour.tour.id, this.currentUser.id)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(
              res => {
                console.log(res);
                if (res.status === 204) {
                  this.tourSvc.onDeleteTourFolder();
                  this.router.navigate(['tours']);
                }
              },
              error => {
                console.log(error);
                this.snackSvc.openSnackBar('ERROR. Deleting tour');
              });
        }
      });
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
