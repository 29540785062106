import {Component, OnDestroy, OnInit} from '@angular/core';
import {TourPax} from '../../../../../shared/models/tour-pax.model';
import {Subject} from 'rxjs';
import {AuthenticationService} from '../../../../../shared/services/session/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TitleService} from '../../../../../shared/services/common/title.service';
import {Title} from '@angular/platform-browser';
import {HttpTourService} from '../../../../../shared/services/http/http-tour.service';
import {ActivetourService} from '../../../../../shared/services/session/activetour.service';
import {SnackbarService} from '../../../../../shared/services/common/snackbar.service';
import {takeUntil} from 'rxjs/operators';
import {Activetour} from '../../../../../shared/models/activetour.model';
import {User} from '../../../../../shared/models/user.model';
import {environment} from '../../../../../../environments/environment';
import {HttpUserService} from '../../../../../shared/services/http/http-user.service';
import {TourOptional} from '../../../../../shared/models/tour-optional.model';
import {HttpTourOptionalService} from '../../../../../shared/services/http/http-tour-optional.service';
import {TourOptPax} from '../../../../../shared/models/tour-opt-pax.model';
import {DatePipe} from '@angular/common';
import {MatDialog} from '@angular/material/dialog';
import {AddPaxComponent} from '../../../../components/tour/add-pax/add-pax.component';
import {EditPaxComponent} from '../../../../components/tour/edit-pax/edit-pax.component';
import {AddUpgRoomComponent} from '../../../../components/hotel/add-upg-room/add-upg-room.component';
import {VersioningService} from '../../../../../shared/services/common/versioning.service';
import {AddPaxListComponent} from '../../../../components/tour/add-pax-list/add-pax-list.component';

@Component({
  selector: 'app-tour-pax',
  templateUrl: './tour-pax.component.html',
  styleUrls: ['./tour-pax.component.scss']
})
export class TourPaxComponent implements OnInit, OnDestroy {
  activeTour: Activetour;
  currentUser: User;
  appUrl: string;
  returnUrl: string;
  linkURL: string;
  copyText: string;
  menuTOOpen: boolean;
  et = false;
  plus = false;
  errpax = false;

  singles: number;
  doubles: number;
  twins: number;
  triples: number;
  quads: number;
  male: number;
  female: number;

  loadingPAX = false;
  loadingTOP = false;
  ok_copied1 = false;
  ok_copied2 = false;
  showHidden = false;
  showInactive = false;
  tAdms: number;

  sortBy: string;
  sortNameAsc = true;
  sortDateAsc = true;
  sortRoomAsc = true;
  sortGendAsc = true;

  tourPax: TourPax[];
  tourPaxActive: TourPax[];
  tourPaxBlock: TourPax[];
  tourPaxHidden: TourPax[];
  tourOptionals: TourOptional[] = [];

  private onDestroy$ = new Subject<boolean>();

  constructor(
    private authSvc: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private titleSvc: TitleService,
    private webTitleSvc: Title,
    private datePipe: DatePipe,
    public dialog: MatDialog,
    private versioning: VersioningService,
    private httpTour: HttpTourService,
    private httpTOSvc: HttpTourOptionalService,
    private httpUser: HttpUserService,
    private activeTourSvc: ActivetourService,
    private snackSvc: SnackbarService) {
    this.authSvc.currentUser
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(user => {
        this.currentUser = user;
      });
    this.activeTourSvc.activeTour
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(tour => {
        this.activeTour = tour;
        this.et = tour?.tour.company_id === 3;
        this.plus = tour?.tour.code.substring(0, 1) === 'X';
      });
    this.webTitleSvc.setTitle('Tour Pax | Planafy');
  }

  ngOnInit() {
    this.appUrl = environment.appUrl;
    const prod_id = this.route.snapshot.params['tour-prodid'];
    const toursUser = this.activeTourSvc.getToursUser();
    // If user has no tours or from tours none is the prodid
    if (!toursUser || !toursUser.find(obj => '' + obj.prodid === '' + prod_id)) {
      this.router.navigate(['tours']);
      this.snackSvc.openSnackBar('Error. Not your tour?');
      return;
    }
    if (!this.activeTour) {
      const tour = toursUser.find(obj => '' + obj.prodid === '' + prod_id);
      const activeTour = new Activetour();
      activeTour.tour = tour;
      this.activeTourSvc.setActiveTour(activeTour);
    }
    this.titleSvc.setTitle(this.activeTour.tour.code + '#' + this.activeTour.tour.prodid);
    if (this.activeTour.tour.prodid + '' !== prod_id) {
      this.snackSvc.openSnackBar('Error. Wrong tour?');
      this.router.navigate(['tours']);
      return;
    }
    if (!this.activeTourSvc.belongsToUser(this.currentUser)) {
      this.snackSvc.openSnackBar('Error. Not your tour?');
      this.router.navigate(['tours']);
      return;
    }
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] ? this.route.snapshot.queryParams['returnUrl'] : null;
    const text0 = '*' + this.activeTour.tour.name + '*\n';
    const text1 = 'Follow the tour itinerary with the Planafy app!\n\n';
    const text2 = '\n1) Create account: Quick sign-up or register\n' +
      '2) At tour invite page click JOIN\n' +
      '3) Choose app store and download free app\n\n' +
      'Or download app, sign-up and use tour password: ' + this.activeTour.tour.prodid;
    this.linkURL = this.appUrl + 'join/' + btoa('' + this.activeTour.tour.id).replace(/=/g, '');
    this.copyText = text0 + text1 + this.linkURL + text2;
    this.sortBy = 'name';
    this.getTourPax(this.activeTour.tour.id);
  }

  processLists(tp) {
    tp.sort((a, b) =>
      a.user['name'].toLowerCase() > b.user['name'].toLowerCase() ? 1 : a.user['name'].toLowerCase() === b.user['name'].toLowerCase() ? 0 : -1);
    this.tourPaxActive = tp.filter(item => item.active === 1 && item.hidden === 0);
    this.tourPaxHidden = tp.filter(item => item.active === 1 && item.hidden === 1);
    this.tourPaxBlock = tp.filter(item => item.active === 0);
    this.tAdms = tp.filter(item => item.active === 1 && item.hidden === 0 && item.user.role > 1).length;
    if (this.sortBy) {
      this.sortPax(this.sortBy);
    }
    this.updateSummary();
  }

  updateSummary() {
    this.singles = 0;
    this.doubles = 0;
    this.twins = 0;
    this.triples = 0;
    this.quads = 0;
    this.male = 0;
    this.female = 0;
    this.errpax = false;
    this.tourPaxActive.map(t => {
      t.warn = '';
      if (!t.room) {
        t.warn = 'room';
        t.err = 'Room type needed';
        this.errpax = true;
      } else {
        if (t.room !== 'Si' && t.user.role > 1) {
          t.warn = 'room';
          t.err = 'Admins must have single room';
          this.errpax = true;
        } else if (this.plus && t.room === 'St') {
          t.warn = 'room';
          t.err = 'No St room in Plus';
          this.errpax = true;
        }
      }
      if (!t.user.gender) {
        t.warn += '-gender';
        if (t.warn.includes('room')) {
          t.err = 'Gender/Room needed';
        } else {
          t.err = 'Gender needed';
        }
      }
      t.last_txt = this.getLastTxt(t.updated_at);
      switch (t.room) {
        case 'Si':
          if (t.user.role < 2) {
            this.singles += 1;
          }
          break;
        case 'Db':
          this.doubles += 1;
          break;
        case 'Tw':
          this.twins += 1;
          break;
        case 'Tr':
          this.triples += 1;
          break;
        case 'Qd':
          this.quads += 1;
          break;
        case 'St':
          if (t.user.gender === 'female') {
            this.female += 1;
          } else if (t.user.gender === 'male') {
            this.male += 1;
          }
          break;
        default:
          break;
      }
    });
    this.tourPaxHidden.map(t => {
        t.last_txt = this.getLastTxt(t.updated_at);
      }
    );
    this.doubles = Math.ceil(this.doubles / 2);
    this.twins = Math.ceil(this.twins / 2);
    this.triples = Math.ceil(this.triples / 3);
    this.quads = Math.ceil(this.quads / 4);
  }

  sortPax(txt) {
    if (txt === 'name') {
      this.sortBy = txt;
      this.tourPaxActive.sort((l, r) => {
        return l.user.name.toLowerCase() > r.user.name.toLowerCase() ? (this.sortNameAsc ? 1 : -1) :
          l.user.name.toLowerCase() < r.user.name.toLowerCase() ? (this.sortNameAsc ? -1 : 1) : 0;
      });
    } else if (txt === 'last') {
      this.sortBy = txt;
      this.sortNameAsc = false;
      this.tourPaxActive.sort((l, r) => {
        return l.updated_at > r.updated_at ? (this.sortDateAsc ? 1 : -1) : l.updated_at < r.updated_at ? (this.sortDateAsc ? -1 : 1) : 0;
      });
    } else if (txt === 'room') {
      this.sortBy = txt;
      this.sortNameAsc = false;
      this.tourPaxActive.sort((l, r) => {
        return l.room > r.room ? (this.sortRoomAsc ? 1 : -1) : l.room < r.room ? (this.sortRoomAsc ? -1 : 1) : 0;
      });
    } else if (txt === 'gender') {
      this.sortBy = txt;
      this.sortNameAsc = false;
      this.tourPaxActive.sort((l, r) => {
        return l.user.gender > r.user.gender ? (this.sortGendAsc ? 1 : -1) : l.user.gender < r.user.gender ? (this.sortGendAsc ? -1 : 1) : 0;
      });
    }
    this.tourPaxActive.sort(
      (a, b) => a.user.role < b.user.role ? 1 : a.user.role === b.user.role ? 0 : -1
    );
  }

  toggleSortPax(txt) {
    if (txt === 'name') {
      this.sortBy = txt;
      this.sortNameAsc = !this.sortNameAsc;
      this.tourPaxActive.sort((l, r) => {
        return l.user.name.toLowerCase() > r.user.name.toLowerCase() ? (this.sortNameAsc ? 1 : -1) :
          l.user.name.toLowerCase() < r.user.name.toLowerCase() ? (this.sortNameAsc ? -1 : 1) : 0;
      });
    } else if (txt === 'last') {
      this.sortBy = txt;
      this.sortNameAsc = false;
      this.sortDateAsc = !this.sortDateAsc;
      this.tourPaxActive.sort((l, r) => {
        return l.updated_at > r.updated_at ? (this.sortDateAsc ? 1 : -1) : l.updated_at < r.updated_at ? (this.sortDateAsc ? -1 : 1) : 0;
      });
    } else if (txt === 'room') {
      this.sortBy = txt;
      this.sortNameAsc = false;
      this.sortRoomAsc = !this.sortRoomAsc;
      this.tourPaxActive.sort((l, r) => {
        return l.room > r.room ? (this.sortRoomAsc ? 1 : -1) : l.room < r.room ? (this.sortRoomAsc ? -1 : 1) : 0;
      });
    } else if (txt === 'gender') {
      this.sortBy = txt;
      this.sortNameAsc = false;
      this.sortGendAsc = !this.sortGendAsc;
      this.tourPaxActive.sort((l, r) => {
        return l.user.gender > r.user.gender ? (this.sortGendAsc ? 1 : -1) : l.user.gender < r.user.gender ? (this.sortGendAsc ? -1 : 1) : 0;
      });
    }
    this.tourPaxActive.sort(
      (a, b) => a.user.role < b.user.role ? 1 : a.user.role === b.user.role ? 0 : -1
    );
  }

  groupBy() {
    const dialogRef = this.dialog.open(AddUpgRoomComponent, {
      data: {'tour_pax': this.tourPaxActive, 'cia_id': this.activeTour.tour.company_id}
    });
    dialogRef.componentInstance.updateGroup.subscribe(result => {
      this.updateSummary();
    });
    dialogRef.afterClosed().subscribe(result => {
      this.updateSummary();
    });
  }

  getLastTxt(updated_at): string {
    let time_amount;
    let time_units;
    const startDate = new Date(updated_at);
    const endDate = new Date();
    const seconds = (endDate.getTime() - startDate.getTime()) / 1000;
    if (seconds < 60) {
      time_amount = Math.floor(seconds);
      time_units = 's';
    } else if (seconds >= 60 && seconds < (60 * 60)) {
      time_amount = Math.floor(seconds / (60));
      time_units = 'm';
    } else if (seconds > (60 * 60) && seconds < (60 * 60 * 24)) {
      time_amount = Math.floor(seconds / (60 * 60));
      time_units = 'h';
    } else if (seconds >= (60 * 60 * 24) && seconds < (60 * 60 * 24 * 365)) {
      time_amount = Math.ceil(seconds / (60 * 60 * 24));
      time_units = 'd';
    } else {
      time_amount = Math.ceil(seconds / (60 * 60 * 24 * 365));
      time_units = 'y';
    }
    return time_amount + time_units;
  }

  recentOn(upd) {
    const min = 4; // 3min
    return new Date(upd).getTime() + min * 60 * 1000 >= new Date().getTime();
  }

  /*
/* HTTP REQUESTS
*******************/
  getTourPax(id) {
    this.loadingPAX = true;
    this.httpTour.getTourPax(id, this.currentUser.id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.versioning.check(res.results.version);
            this.tourPax = res.results.tour_paxs;
            this.processLists(this.tourPax);
            this.tourOptionals = res.results.tour_optionals;
            this.activeTour.tour.tour_settings.visible = res.results.visible;
            if (!this.activeTour.tour.tour_settings.visible) {
              this.activeTour.tour.tour_settings.room_prefs = 'non';
            }
            this.activeTourSvc.setActiveTour(this.activeTour);
          } else {
            this.snackSvc.resultsElse(res);
          }
          this.loadingPAX = false;
        },
        error => {
          console.log(error);
          this.loadingPAX = false;
        });
  }

  onQuickAdd() {
    // if (this.et) {
    //   const dialogRef = this.dialog.open(AddPaxListComponent, {
    //     autoFocus: true,
    //     data: {'user': this.currentUser}
    //   });
    //   dialogRef.afterClosed().subscribe(result => {
    //     console.log(result);
    //     // if (result && result !== 'close') {
    //     // }
    //   });
    // } else {
    const dialogRef = this.dialog.open(AddPaxComponent, {
      autoFocus: true,
      data: {'user': this.currentUser}
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
      if (result && result['reason'] !== 'close') {
        this.onAddPAX(result['user'], result['room'] ? result['room'] : null);
      }
    });
    // }
  }

  onAddPAX(user, room?) {
    const data = {
      'tour_id': this.activeTour.tour.id,
      'user_id': user.id,
    };
    // console.log(data, room);
    this.httpTour.createTourPax(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            if (!res.message.includes('already')) {
              if (room) {
                this.updateTourPax(res.results.tour_pax, [{'key': 'room', 'value': room}]);
              } else {
                this.tourPax.push(res.results.tour_pax);
              }
            } else {
              this.snackSvc.openSnackBar(res.message);
            }
            this.processLists(this.tourPax);
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              console.log(res.message.toString());
            }
          }
        },
        error => {
          console.log(error);
          this.snackSvc.openSnackBar('Error adding user to tour');
        });
  }

  onAddTourOptPAX(tpax: TourPax, to: TourOptional, paid?: string) {
    this.loadingTOP = true;
    const params = {
      'tour_pax_id': tpax.id,
      'tour_optional_id': to.id,
      'url': environment.selfUrl,
    };
    if (paid) {
      params['amount_paid'] = paid;
    }
    // console.log(params);
    this.httpTOSvc.createTourOptPax(params)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            if (!res.message.includes('already')) {
              tpax.tour_opt_pax.push(res.results.top);
            } else {
              this.deleteTourOptPax(res.results.top);
            }
          } else {
            this.snackSvc.resultsElse(res);
          }
          this.loadingTOP = false;
        },
        error => {
          console.log(error);
          this.loadingTOP = false;
        });
  }

  deleteTourOptPax(tourOptPax: TourOptPax) {
    this.httpTOSvc.deleteTourOptPax(tourOptPax.tour_pax_id, tourOptPax.tour_optional_id, 0, 0)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.snackSvc.openSnackBar('Pax removed from activity');
            this.onRefreshTP();
          } else {
            this.snackSvc.resultsElse(res);
          }
          this.loadingTOP = false;
        },
        error => {
          console.log(error);
          this.loadingTOP = false;
        });
  }

  TOPaid(to_id, top) {
    if (!top) {
      top = [];
    }
    let res = null;
    if (top.findIndex(it => it.tour_optional_id === to_id && it.paid_at) >= 0) {
      res = 'paid';
    } else if (top.findIndex(it => it.tour_optional_id === to_id) >= 0) {
      res = 'su';
    }
    return res;
  }

  markAsPaid(tpax: TourPax, to: TourOptional) {
    if (!to.price) {
      const snackbarRef = this.snackSvc.openSnackBar('Error. Provide public price', 'GO');
      snackbarRef.afterDismissed()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(reason => {
          if (reason.dismissedByAction) {
            this.router.navigate(['tour', this.activeTour.tour.prodid, 'activities', to.id], {queryParams: {returnUrl: window.location.pathname}});
          }
        });
      return;
    }
    // If mark as paid already signed up
    const topIDx = tpax.tour_opt_pax.findIndex(top => top.tour_optional_id === to.id);
    if (topIDx >= 0) {
      tpax.tour_opt_pax[topIDx].amount_paid = to.price;
      this.updateTourOptionalPax(tpax, tpax.tour_opt_pax[topIDx], 'paid_at');
    } else {
      // Sign up and also mark as paid
      this.onAddTourOptPAX(tpax, to, to.price);
    }
  }

  updateTourOptionalPax(tpax: TourPax, tourOptPax: TourOptPax, txt?) {
    this.loadingTOP = true;
    let data = {};
    if (txt === 'paid_at') {
      if (!tourOptPax.amount_paid || /^\s*$/.test(tourOptPax.amount_paid)) {
        data = {
          'amount_paid': null,
          'paid_at': null,
          'notes': null,
        };
      } else {
        data = {
          'amount_paid': tourOptPax.amount_paid,
          'notes': 'cash',
          'paid_at': this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss', 'es-ES'),
        };
      }
    } else if (txt === '!paid_at') {
      data = {
        'amount_paid': null,
        'paid_at': null,
        'notes': null,
        'delete': true,
      };
    } else if (txt === 'diff') {
      data = {
        'diff': tourOptPax.diff,
      };
    } else if (txt === 'notes') {
      data = {
        'notes': tourOptPax.notes,
      };
    }
    // console.log(data);
    this.httpTOSvc.updateTourOptionalPax(tourOptPax.id, data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            const idx = tpax.tour_opt_pax.findIndex(top => +top.id === +res.results.top.id);
            tpax.tour_opt_pax[idx] = res.results.top;
          } else {
            this.snackSvc.resultsElse(res);
          }
          this.loadingTOP = false;
        },
        error => {
          console.log(error);
          this.loadingTOP = false;
        });
  }

  onDeleteUser(tp) {
    // Show snackbar to undo delete TOP
    const snackbarRef = this.snackSvc.openSnackBar('Remove user?', 'OK');
    snackbarRef.afterDismissed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(reason => {
        // ACTION = OK
        if (reason.dismissedByAction) {
          this.httpUser.deleteUser(tp.user.id, this.currentUser.id, 'true')
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(
              res => {
                console.log(res);
                if (res.status < 400) {
                  const idx = this.tourPaxActive.findIndex(tpax => +tpax.id === +tp.id);
                  this.tourPaxActive.splice(idx, 1);
                } else {
                  if (res.results) {
                    this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
                  } else {
                    console.log(res.message.toString());
                  }
                }
              },
              error => {
                console.log(error);
                this.snackSvc.openSnackBar('Error removing user');
              });
        }
      });
  }

  // Set Active/Hidden
  onSetActive(tour_pax) {
    this.updateTourPax(tour_pax, [{'key': 'active', 'value': true}, {'key': 'hidden', 'value': false}]);
  }

  onSetInactive(tour_pax) {
    this.updateTourPax(tour_pax, [{'key': 'active', 'value': false}, {'key': 'hidden', 'value': false}]);
  }

  onSetHidden(tour_pax) {
    this.updateTourPax(tour_pax, [{'key': 'active', 'value': true}, {'key': 'hidden', 'value': true}]);
  }

  onSetVisible(tour_pax) {
    this.updateTourPax(tour_pax, [{'key': 'active', 'value': true}, {'key': 'hidden', 'value': false}]);
  }

  updateTourPax(tour_pax, values: any) {
    const params = {
      'req_id': this.currentUser.id,
    };
    for (const i of values) {
      params[i.key] = i.value;
    }
    // console.log(params);
    this.httpTour.updateTourPax(tour_pax.id, params)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            // If not active blocked
            if (!res.results.tp.active) {
              const idx1 = this.tourPaxActive.findIndex(tp => '' + tp.id === '' + tour_pax.id);
              if (idx1 >= 0) {
                this.tourPaxActive.splice(idx1, 1);
              }
              const idx3 = this.tourPaxHidden.findIndex(tp => '' + tp.id === '' + tour_pax.id);
              if (idx3 >= 0) {
                this.tourPaxHidden.splice(idx3, 1);
              }
              this.tourPaxBlock.push(res.results.tp);
              this.tourPaxBlock.sort(function (a, b) {
                return a.user.name.localeCompare(b.user.name);
              });
              if (res.results.tp.user.role > 1) {
                this.tAdms = this.tAdms - 1;
              }
            } else {
              // If active: normal or hidden
              if (res.results.tp.hidden) {
                const idx1 = this.tourPaxActive.findIndex(tp => '' + tp.id === '' + tour_pax.id);
                if (idx1 >= 0) {
                  this.tourPaxActive.splice(idx1, 1);
                }
                const idxIB = this.tourPaxBlock.findIndex(tp => '' + tp.id === '' + tour_pax.id);
                if (idxIB >= 0) {
                  this.tourPaxBlock.splice(idxIB, 1);
                }
                this.tourPaxHidden.push(res.results.tp);
                this.tourPaxHidden.sort(function (a, b) {
                  return a.user.name.localeCompare(b.user.name);
                });
                if (res.results.tp.user.role > 1) {
                  this.tAdms = this.tAdms - 1;
                }
              } else {
                const idxIB = this.tourPaxBlock.findIndex(tp => '' + tp.id === '' + tour_pax.id);
                if (idxIB >= 0) {
                  this.tourPaxBlock.splice(idxIB, 1);
                }
                const idx1 = this.tourPaxHidden.findIndex(tp => '' + tp.id === '' + tour_pax.id);
                if (idx1 >= 0) {
                  this.tourPaxHidden.splice(idx1, 1);
                }
                this.tourPaxActive.push(res.results.tp);
                this.tourPaxActive.sort(function (a, b) {
                  return a.user.name.localeCompare(b.user.name);
                });
                if (res.results.tp.user.role > 1) {
                  this.tAdms = this.tAdms + 1;
                }
              }
            }
            this.updateSummary();
          } else {
            if (tour_pax.active) {
              this.tourPaxActive.push(tour_pax);
            } else {
              this.tourPaxBlock.push(tour_pax);
            }
            this.snackSvc.resultsElse(res);
          }
        },
        error => {
          if (tour_pax.active) {
            this.tourPaxActive.push(tour_pax);
          } else {
            this.tourPaxBlock.push(tour_pax);
          }
          console.log(error);
        });
  }

  // Open dialog
  onEditTourPAX(tp: TourPax) {
    const dialogRef = this.dialog.open(EditPaxComponent, {
      maxWidth: '90vw',
      data: {'tourpax': tp, 'req_id': this.currentUser.id, 'cia_id': this.activeTour.tour.company_id}
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
      if (result && result['reason'] !== 'close' && result['reason'] !== 'delete') {
        tp.user = result.tourpax.user;
        tp.room = result.tourpax.room;
        tp.active = result.tourpax.active;
        tp.hidden = result.tourpax.hidden;
        const tpidx = this.tourPax.findIndex(t => +t.id === +tp.id);
        this.tourPax[tpidx] = tp;
        this.processLists(this.tourPax);
      } else if (result && result['reason'] === 'delete') {
        const tpIdx = this.tourPaxActive.findIndex(tpax => +tp.id === +tpax.id);
        this.tourPaxActive.splice(tpIdx, 1);
        this.updateSummary();
      } else {
        this.updateSummary();
      }
    });
  }

  // Remove from tour
  onRemove(tpax) {
    // Show snackbar to undo delete TOP
    const snackbarRef = this.snackSvc.openSnackBar('Remove from tour?', 'OK');
    snackbarRef.afterDismissed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(reason => {
        // ACTION = OK
        if (reason.dismissedByAction) {
          this.httpTour.deleteTourPax(tpax.id, this.currentUser.id)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(
              res => {
                console.log(res);
                if (res.status === 204) {
                  const tpIdx = this.tourPax.findIndex(tp => +tp.id === +tpax.id);
                  if (tpIdx >= 0) {
                    this.tourPax.splice(tpIdx, 1);
                  }
                  this.processLists(this.tourPax);
                }
              },
              error => {
                console.log(error);
              });
        }
      });
  }

  // Other

  onRefreshTP() {
    this.getTourPax(this.activeTour.tour.id);
  }

  openLink() {
    window.open(this.linkURL, '_blank');
  }

  notify(txt?) {
    if (txt === '1') {
      this.ok_copied1 = true;
      setTimeout(() => {
        this.ok_copied1 = false;
      }, 2000);
    } else {
      this.ok_copied2 = true;
      this.snackSvc.openSnackBar('Instructions copied');
      setTimeout(() => {
        this.ok_copied2 = false;
      }, 2000);
    }
  }

  goBack() {
    if (this.returnUrl && this.returnUrl !== 'undefined') {
      this.router.navigate([this.returnUrl]);
    } else {
      this.activeTourSvc.setActiveTour(this.activeTour);
      this.router.navigate(['tour', this.activeTour.tour.prodid]);
    }
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
